import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/Services.css";

function Services() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Experts from the industry, at your disposal.</h1>
          <p>
          Industry experts, available to support your needs and goals.
With our expert consultants embedded in your team, you'll confidently and seamlessly launch projects that cover every dimension of the asset management lifecycle.

          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>
            We stand by your side through every phase, from outlining the plan to realizing your objectives.


            </h1>
            <p>
            We have traversed extensive terrain. Drawing on decades of practical expertise, our consultants enable you to envision and attain success. Whether innovating new pathways or modernizing your technology, rely on us to navigate complexities and steer you towards optimal results.

            </p>
          </div>
        </div>
      </div>
      <div className="inner-section-2-bg">
        <div className="wrapper-1">
          <div className="services-card2-section">
            <div className="services-card2-1">
              <h3>Consulting Services </h3>
              <p>
              Our consulting services excel in executing transformational projects at scale, backed by a proven track record and seasoned consultants. With decades of hands-on experience, we drive innovation and modernization, navigating complexities to deliver optimal outcomes. Trust our expertise to guide you through large-scale transformations, ensuring success and sustained growth

             </p>

              <NavLink
                to="/Advisory-Service"
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Delivery Services</h3>
              <p>
              Our delivery services ensure seamless, reliable, and efficient deployment of technology solutions. Utilizing advanced methodologies and a skilled team, we guarantee timely and secure implementation. Focused on excellence and client satisfaction, we offer customized IT solutions that meet your specific needs, ensuring optimal performance and business growth. 
              </p>
              <NavLink
                to="/Delivery-Service"
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
