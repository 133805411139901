import React from "react";
import Carousel from "react-bootstrap/Carousel";
import bannerImg1 from "../images/n-banner-1.png";
import bannerImg2 from "../images/n-banner-2.jpg";
import bannerImg3 from "../images/n-banner-3.jpg";
import bannervideo from "./banner.mp4";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BannerAnimation.css";
import { TypeAnimation } from "react-type-animation";

function BannerAnimation() {
  return (
    <div className="banner-staic-img">
      {/* <img src={bannerImg1}></img> */}
      <video
        width="100%"
        height="auto"
        autoplay="true"
        loop="true"
        muted="true"
        playsinline="true"
      >
        <source src={bannervideo} type="video/mp4" />
      </video>
      <div class="carousel-caption">
        <h3>
          <TypeAnimation
            sequence={[
              // Driving Excellence Across the Investment Management Lifecycle with Expertise, Innovation, and Strategic Solutions
              // Same substring at the start will only be typed out once, initially
              "Driving Excellence Across the Investment Management Lifecycle with Expertise, Innovation, and Strategic Solutions",
              3000, // wait 1s before replacing "Mice" with "Hamsters"
              "",
              3000,
              // "Lead",
              // 500,
              // "Transform. Innovate. Lead.",
              // 3500,
            ]}
            wrapper="span"
            speed={50}
            style={{ display: "inline-block" }}
            repeat={Infinity}
          />
          <br />
        </h3>
      </div>

      {/*<Carousel>
      <Carousel.Item interval={1000}>
        <img src={bannerImg1}></img>
        <Carousel.Caption>
          <h3>
            TechVest Global - Your driven, strategic, and results-oriented
            partner for transformative solutions.
          </h3>
        </Carousel.Caption>
      </Carousel.Item>

     <Carousel.Item interval={500}>
        <img src={bannerImg2}></img>
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={bannerImg3}></img>
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
     
    </Carousel> */}
    </div>
  );
}

export default BannerAnimation;
