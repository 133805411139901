import React from "react";
import BannerAnimation from "./BannerAnimation/BannerAnimation";
import "./styles/Home.css";
import SectionOneImg from "./images/section-1-icon.svg";
import SectionTwoImg from "./images/section-2-icon.svg";
import SectionThreeImg from "./images/section-3-icon.svg";
import ServicesImg from "./images/services-img1.png";
import ExpertiseImg from "./images/expertise-img1.png";
import AssetServicing from "./images/asset-servicing.png";
import NonTraditional from "./images/non-traditional-investment-strategies.png";
import TraditionalInvestment from "./images/traditional-investment-strategies.png";
import LinkOne from "./images/link-1.svg";

import ExpertiseVideo from "./Expertise-Video.mp4";

import ServicesVideo from "./Services-Video.mp4";

function Home() {
  return (
    <div>
      <BannerAnimation />
      <div className="section-1-bg">
        <div className="wrapper-1">
          <div className="section-1-wrap">
            <div className="section-1-1">
              <h1>
                <span className="black-color">Your Strategic </span>
                Transformation Partner for Unmatched{" "}
                {/* <span className="black-color">Trusted Guide at the </span> */}
                <span className="blue-color">Success</span>.
              </h1>
            </div>
            <div className="section-1-2">
              <div className="section-1-2-wrap">
                <div className="section-1-2-img">
                  <img src={SectionOneImg} alt="" />
                </div>
                <p>
                  TechVest Global partners with leading asset management firms
                  and service providers globally to develop effective solutions
                  for the complexities of modern business challenges. We
                  specialize in assessing, designing, and implementing
                  operational and technological improvements that drive results
                  across the entire investment management lifecycle.
                </p>
              </div>
              <div className="section-1-2-wrap">
                <div className="section-1-2-img">
                  <img src={SectionTwoImg} alt="" />
                </div>
                <p>
                  Your enterprise is poised for a technological revolution. As
                  the wave of innovation approaches, you may be hindered by
                  obsolete processes, legacy systems, or strategies that carry
                  significant costs and risks. You need a consulting partner
                  with deep technical expertise to navigate these complex
                  business challenges and the foresight to help you realize your
                  future vision.
                </p>
              </div>
              <div className="section-1-2-wrap">
                <div className="section-1-2-img">
                  <img src={SectionThreeImg} alt="" />
                </div>
                <p>
                  Partnering with the world’s foremost investment managers and
                  third-party service providers, TechVest Global excels in
                  mastering the complexities of wealth management, From hedge
                  funds and insurers to institutional asset managers, we deliver
                  unparalleled expertise and deep insights into the intricacies
                  of money management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2-bg">
        <div className="section-2-wrap">
          <div className="section-2-1">
            <div className="section-2-1-textwrap">
              <h2>Services</h2>
              <p>
                From driving comprehensive digital transformation initiatives to
                seamlessly modernizing individual applications, TechVest Global
                is equipped to address any challenge with you. Our experienced
                consulting team combines visionary foresight with practical
                expertise, guiding you toward your strategic objectives with
                confidence and precision.
              </p>
              <a href="/Services" className="button-1">
                Read More
              </a>
            </div>
          </div>
          <div className="section-2-2">
            {/* <img src={ServicesImg} alt="ServicesImg" /> */}
            <video
              width="100%"
              height="auto"
              autoplay="true"
              loop="true"
              muted="true"
              playsinline="true"
              className="video-1"
            >
              <source src={ServicesVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <div className="section-2-bg">
        <div className="section-2-wrap">
          <div className="section-2-2">
            {/* <img src={ExpertiseImg} alt="ExpertiseImg" /> */}
            <video
              width="100%"
              height="auto"
              autoplay="true"
              loop="true"
              muted="true"
              playsinline="true"
              className="video-1"
            >
              <source src={ExpertiseVideo} type="video/mp4" />
            </video>
          </div>
          <div className="section-2-1 section-3-1">
            <div className="section-2-1-textwrap section-3-1-textwrap">
              <h2>Expertise</h2>
              <p>
                TechVest Global combines deep expertise in investment management
                with cutting-edge IT and digital transformation solutions,
                including AI integration. We excel in navigating complex
                financial landscapes and driving innovative change. Our skilled
                consultants bring visionary foresight and practical experience,
                ensuring seamless transitions and strategic advancements.
                Whether enhancing operational efficiency or implementing
                advanced technologies, we are committed to guiding you toward
                achieving your business goals with precision and confidence.
              </p>
              <a href="/Expertise" className="button-1">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-3-bg">
        <div className="wrapper-1">
          <h2 className="text-center">
            Discover how we can enhance your
            <span className="black-color"> Investment </span> and
            <span className="black-color"> Business </span>
            <span className="blue-color"> Strategies.</span>
          </h2>
          <div className="three-col-main card-sec  m-b-1">
            <div className="three-col-1">
              <img src={TraditionalInvestment} alt="" />
              <h3>
                Investment
                <br />
                Strategies
              </h3>
              <p>
                Advanced IT solutions tailored for traditional investment
                strategies, integrating cutting-edge technologies to enhance
                efficiency and performance. Our expertise spans AI-driven
                analytics, blockchain for secure transactions, and cloud
                computing for scalable operations. By modernizing legacy systems
                and implementing innovative digital tools, we empower investment
                managers to make data-driven decisions, optimize portfolio
                management, and streamline compliance.
              </p>
            </div>

            <div className="three-col-1">
              <img src={NonTraditional} alt="" />
              <h3>
                Non-Traditional
                <br />
                Investment Strategies
              </h3>
              <p>
                We provide advanced solutions for non-traditional investment
                strategies, leveraging AI, and big data analytics. Our
                innovative technologies enhance decision-making, improve risk
                management, and optimize returns. With expertise in alternative
                assets, our skilled consultants deliver tailored digital tools,
                ensuring agility and precision in navigating complex, evolving
                financial markets. Embrace the future of investments with
                TechVest Global.
              </p>
            </div>

            <div className="three-col-1">
              <img src={AssetServicing} alt="" />
              <h3>
                Asset
                <br />
                Servicing
              </h3>
              <p>
                We excel in delivering complex IT integration solutions for
                asset managers, blending cutting-edge technologies with
                predictable implementation goals. Our proficiency in advanced
                technologies boosts portfolio management, risk assessment, and
                operational efficiency. Our expert consultants ensure seamless
                transitions, enabling asset managers to adeptly navigate the
                evolving financial landscape.
              </p>
            </div>
          </div>

          <div className="text-center">
            <a href="/Markets-Served" className="button-3">
              Learn More
            </a>
          </div>
        </div>
      </div>

      <div className="section-5-bg">
        <div className="wrapper-1">
          <h2 className="text-center">
            Interested in discovering how
            <br />
            <span className="black-color">TechVest Global</span> can help you
            achieve your goals?
            <br />
            Contact us today to connect with our
            <br />
            <span className="blue-color">Team of experts.</span>
          </h2>
          <div className="line-1"></div>
          <div className="section-5-wrap">
            <div className="section-5-1">
              <h2>Insights</h2>
              <p>
                Gain valuable insights on asset management technology and
                operations from our expert consulting team and senior thought
                leaders. Discover the latest trends, cutting-edge technologies,
                and best practices driving the future of financial services.
              </p>
              <a href="/Insights" className="button-2">
                Read All
              </a>
            </div>
            <div className="section-5-2">
              <div className="section-5-card">
                <h5>2024 Trends in Global Financial Services</h5>
                <p>
                  In analyzing the global financial trends of 2024, we must
                  examine a variety of factors that shape the economic
                  landscape..
                </p>
                <div className="section-5-link">
                  <a href="/blog/TrendsInGlobalFinancialServices2024">
                    <img src={LinkOne} alt="LinkOne" />
                  </a>
                </div>
              </div>

              <div className="section-5-card">
                <h5>IBOR and ABOR Impacts in 2024 and beyond</h5>
                <p>
                  The future of Investment Book of Record (IBOR) and Accounting
                  Book of Record (ABOR) in the financial industry is shaped..
                </p>
                <div className="section-5-link">
                  <a href="blog/IBORAndABORImpactsIn2024AndBeyond">
                    <img src={LinkOne} alt="LinkOne" />
                  </a>
                </div>
              </div>

              <div className="section-5-card">
                <h5>Generative AI Impact O Asset Management Firms</h5>
                <p>
                  Generative AI, encompassing technologies capable of producing
                  data, text, images, and other content through learning from
                  vast datasets..
                </p>
                <div className="section-5-link">
                  <a href="blog/GenerativeAIImpactOnAssetManagementFirms">
                    <img src={LinkOne} alt="LinkOne" />
                  </a>
                </div>
              </div>

              <div className="section-5-card">
                <h5>Best Practices In Simcorp Implementation Testing</h5>
                <p>
                  TSimCorp Dimension is a leading investment management solution
                  used by financial institutions worldwide to manage their
                  assets, trades..
                </p>
                <div className="section-5-link">
                  <a href="blog/BestPracticesInSimcorpImplementationTesting">
                    <img src={LinkOne} alt="LinkOne" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
