import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import { Link } from "react-router-dom";

function BenchmarkingSolutions() {
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Benchmarking Solutions</h1>
          <p>
            Chart a New Course or Stay the Path? Our Benchmarking Services
            Provide Essential Insights for Informed Decisions
          </p>
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <Link to="/Advisory-Service">Consulting Services</Link>
            <a href="#">Benchmarking Solutions</a>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="services-section-wrap text-left">
            <div className="services-section-1">
              <div className="services-section-text1">
                <h1>
                  <span className="black-color">
                    Efficient Vendor Comparison
                  </span>
                </h1>
                <p>
                  Selecting the right vendors is crucial for financial asset
                  managers striving to enhance their operations and stay
                  competitive. TechVest’s Benchmarking Solutions provide you
                  with a systematic and efficient approach to comparing vendors.
                  Our comprehensive evaluations cover key performance metrics,
                  service offerings, and cost structures, ensuring you make
                  well-informed decisions. By leveraging our expertise, you can
                  swiftly identify the vendors that best align with your
                  strategic goals and operational requirements.
                </p>
              </div>
            </div>
            <div className="services-section-2">
              <div className="services-section-2-wrap">
                <h3>In-Depth Market Landscape Analysis</h3>
                {/* <h6>
                  Stay ahead of the curve by evaluating the software and
                  services market.
                </h6> */}
                <p>
                  Understanding the market landscape is essential for making
                  strategic decisions. TechVest offers in-depth analyses of the
                  current market conditions, trends, and emerging technologies.
                  Our Benchmarking Solutions provide a detailed overview of the
                  competitive environment, helping you recognize opportunities
                  and potential challenges. With our insights, you gain a
                  clearer understanding of where you stand relative to industry
                  standards and how you can improve your positioning.
                </p>
              </div>
              <div className="services-section-2-wrap">
                <h3>Accelerate Option Exploration .</h3>
                {/* <h6>
                  Boost efficiency while minimizing costs with Citisoft
                  Benchmarking Services.
                </h6> */}
                <p>
                  Time is of the essence when evaluating potential vendors and
                  solutions. TechVest accelerates your exploration of options by
                  streamlining the benchmarking process. Our efficient
                  methodologies and advanced tools enable you to quickly assess
                  multiple vendors, reducing the time and effort required to
                  identify the best fit for your needs. We handle the heavy
                  lifting, so you can focus on strategic decision-making and
                  implementation.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Leverage Expert Insights</h3>
                {/* <h6>
                  Harness the knowledge of industry experts with Citisoft
                  Benchmarking Services.
                </h6> */}
                <p>
                  Tap into seasoned insights from TechVest’s experienced
                  consultants. Our Benchmarking Solutions are backed by years of
                  industry expertise and a deep understanding of asset
                  management. We provide you with actionable recommendations
                  based on comprehensive data and expert analysis. By leveraging
                  our insights, you can confidently make decisions that enhance
                  your operational efficiency and drive business growth.
                </p>
              </div>

              {/* <div className="services-section-2-wrap">
                <h3>Embrace tailored solutions</h3>
                <h6>Dare to be different.</h6>
                <p>
                  Unlock the full potential of outsourcing by adopting a
                  strategic, customized approach. Securing partners who
                  seamlessly integrate with your team requires insight into
                  their capabilities and culture. We recognize the significance
                  of alignment and will assist you in evaluating service
                  providers based on your specific operational requirements.
                  From assessing functional capabilities to ensuring day-to-day
                  support, we'll tailor your selection process to fit your
                  unique needs.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Master the details</h3>
                <h6>Navigate outsourcing contracts with finesse.</h6>
                <p>
                  Negotiating an outsourcing contract demands expertise and
                  finesse. With our guidance, you can tackle contracts of any
                  scale confidently. Drawing from our extensive experience, we
                  offer invaluable peer benchmarking insights and a thorough
                  understanding of the negotiation process. Together, we'll
                  ensure you secure favorable terms, clarify your partnership
                  dynamics, and anticipate future developments, safeguarding you
                  from unexpected surprises.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div class="text-center">
            <a href="/Advisory-Service" class="button-3">
              Back to Consulting Services
            </a>
          </div>
          <div className="line-2"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>

            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenchmarkingSolutions;
