import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import "../../styles/About.css";
import CareersSubData from "./CareersSubData"; // Assuming the file path where CareersSubData is located

function CareersList() {
  const { id } = useParams();
  const [CareersData, SetCareersData] = useState([]);
  const [selectedJob, setSelectedJob] = useState("All");
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [selectedExperience, setSelectedExperience] = useState("All");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchCareersData = async () => {
      try {
        const response = await fetch(
          "https://techvestglobal.com/api/career.php"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        SetCareersData(jsonData || []);
        setFilteredData(jsonData || []); // Initialize filtered data with all data
      } catch (error) {
        console.error("Error fetching data category:", error);
      }
    };

    fetchCareersData();
  }, []);

  const applyFilters = (e) => {
    e.preventDefault();
    const filtered = CareersData.filter((item) => {
      return (
        (selectedJob === "All" || item.job_title === selectedJob) &&
        (selectedLocation === "All" || item.location === selectedLocation) &&
        (selectedExperience === "All" || item.experience === selectedExperience)
      );
    });
    setFilteredData(filtered);
  };

  const onClickClearBtn = (e) => {
    e.preventDefault();
    // Reset filter states
    setSelectedJob("All");
    setSelectedLocation("All");
    setSelectedExperience("All");
    // Show all data
    setFilteredData(CareersData);
  };

  let data;
  if (id === "CareerOpportunities") {
    data = CareersSubData.Opportunities;
  } else {
    return (
      <div>
        <h1>Data not found!</h1>
      </div>
    );
  }

  return (
    <div>
      {/* Inner banner section */}
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>{data.title}</h1>
          <p>{data.BannerText}</p>
        </div>
      </div>

      {/* Section with main content */}
      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>{data.Heading}</h1>
            <p>{data.HeadingDescription}</p>
          </div>
        </div>
      </div>

      {/* Filtering the jobs list */}

      {/* Render content based on the selected section */}
      <div className="team-sec1-bg">
        <div className="wrapper-1">
          <div>
            <div className="filltering_list_container">
              <form onSubmit={applyFilters}>
                <div className="filter-main-wrap">
                  <div className="filter-main-1">
                    <select
                      value={selectedJob}
                      onChange={(e) => setSelectedJob(e.target.value)}
                    >
                      <option value="Job">Job</option>

                      {Array.from(
                        new Set(CareersData.map((item) => item.job_title))
                      ).map((job, index) => (
                        <option key={index} value={job}>
                          {job}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="filter-main-1">
                    <select
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                    >
                      <option value="Location">Location</option>
                      {Array.from(
                        new Set(CareersData.map((item) => item.location))
                      ).map((location, index) => (
                        <option key={index} value={location}>
                          {location}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="filter-main-1">
                    <select
                      value={selectedExperience}
                      onChange={(e) => setSelectedExperience(e.target.value)}
                    >
                      <option value="Experience">Experience</option>
                      {Array.from(
                        new Set(CareersData.map((item) => item.experience))
                      ).map((experience, index) => (
                        <option key={index} value={experience}>
                          {experience} Year
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="filter-main-2">
                    <button type="submit">Search</button>
                  </div>
                  <div className="filter-main-2">
                    <button type="button" onClick={onClickClearBtn}>
                      Clear
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div className="opportunities-card" key={item.id}>
                <div className="opp-sub-card">
                  <div>
                    <h3>{item.job_title}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.requirements }}
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />

                    <div className="filter-main-footer-wrap">
                      <div className="filter-main-footer-1">
                        <b>Location:</b>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.location }}
                        />
                      </div>
                      {/* <div className="filter-main-footer-2">
                        <b>Experience:</b>
                        <span
                          {dangerouslySetInnerHTML={{ __html: item.experience }} ? "Year" : ""}
                        /> 
                      </div> */}
                      <div className="filter-main-footer-2">
                        <b>Experience:</b>
                        <span>
                          {item.experience} {item.experience ? "Year" : ""}
                        </span>
                      </div>
                      <div className="filter-main-footer-3">
                        <NavLink
                          to={`/Careers/OpportunitiesInner/${item.id}`}
                          className="button-3-small"
                        >
                          Apply Now
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No jobs found</p>
          )}
        </div>
      </div>

      {/* Additional section */}
      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="line-3"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareersList;
