import React, { useState } from "react";
import "../styles/About.css";
import Managementteam from "../images/management-team.png";
import Location from "../images/location-1.svg";

function ContactUs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [region, setRegion] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (!firstName.trim()) {
      tempErrors["firstName"] = "First Name is required";
      isValid = false;
    }
    if (!lastName.trim()) {
      tempErrors["lastName"] = "Last Name is required";
      isValid = false;
    }
    if (!email.trim()) {
      tempErrors["email"] = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors["email"] = "Email address is invalid";
      isValid = false;
    }
    if (!phoneNumber.trim()) {
      tempErrors["phoneNumber"] = "Phone Number is required";
      isValid = false;
    }

    // if (!phoneNumber.trim()) {
    //   tempErrors["phoneNumber"] = "Phone Number is required";
    //   isValid = false;
    // } else if (!/^\d$/.test(phoneNumber)) {
    //   tempErrors["phoneNumber"] = "Phone Number is invalid";
    //   isValid = false;
    // }
    // if (!companyName.trim()) {
    //   tempErrors["companyName"] = "Company Name is required";
    //   isValid = false;
    // }
    // if (!jobTitle.trim()) {
    //   tempErrors["jobTitle"] = "Job Title is required";
    //   isValid = false;
    // }
    // if (!region.trim()) {
    //   tempErrors["region"] = "Region is required";
    //   isValid = false;
    // }
    // if (!message.trim()) {
    //   tempErrors["message"] = "Message is required";
    //   isValid = false;
    // }

    setErrors(tempErrors);
    return isValid;
  };

  const onSubmitContactBtn = async (e) => {
    e.preventDefault();
    if (validate()) {
      const newData = {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phoneNumber,
        job_title: jobTitle,
        companyname: companyName,
        region: region,
        message: message,
      };

      console.log(newData);

      const postData = async () => {
        try {
          const response = await fetch(
            "https://techvestglobal.com/api/contact-form.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newData),
            }
          );

          if (response.ok === true) {
            const data1 = await response.json();
            console.log("response from post", data1);
            setSuccessMessage("Form submitted successfully!");

            // Clear the input fields
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhoneNumber("");
            setCompanyName("");
            setRegion("");
            setMessage("");
            setJobTitle("");
          } else {
            const errorText = await response.text();
            console.error("Server response:", errorText);
            throw new Error("Failed to upload");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      postData();
    }
  };

  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Contact Us</h1>
          <p>
            For further information about Techvest Global, our team, and our
            capabilities, please feel free to reach out to any of our listed
            locations below. We're here to assist you in any way we can.
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <div className="contact-form-bg">
              <h2>Get In Touch</h2>

              <div className="our-vendor-relationship-form-flex">
                <form>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <div className="ff-1">
                    <input
                      type="number"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errors.phoneNumber && (
                      <p className="error">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    {errors.companyName && (
                      <p className="error">{errors.companyName}</p>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Job Title"
                      name="jobTitle"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                    {errors.jobTitle && (
                      <p className="error">{errors.jobTitle}</p>
                    )}
                  </div>
                  <div className="ff-1 w-100">
                    <input
                      type="text"
                      placeholder="Region"
                      name="region"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                    />
                    {errors.region && <p className="error">{errors.region}</p>}
                  </div>
                  <div className="ff-1 w-100">
                    <textarea
                      placeholder="Message"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    {errors.message && (
                      <p className="error">{errors.message}</p>
                    )}
                  </div>
                  <div className="ff-1 w-100 text-left">
                    <b>
                      <a href="#">Read Our Privacy Policy Here</a>
                    </b>
                  </div>
                  <div className="ff-1 w-100 text-left">
                    <button className="button-3" onClick={onSubmitContactBtn}>
                      Submit
                    </button>
                  </div>
                  {successMessage && (
                    <p className="success">{successMessage}</p>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="wrap-1">
            <div className="contact-form-bg">
              <div className="contact-location-wrap">
                <div className="contact-location-1-full">
                  <div className="contact-location-1-1">
                    <img src={Location} alt="Location" />
                  </div>
                  <div className="contact-location-1-2">
                    <h4>Corporate Office Address: </h4>
                    <div className="contact-flex-wrap">
                      <div className="contact-flex-1">
                        <h5>Canada:</h5>
                        <p>
                          1 Dundas Street W, Suite # 2500 Toronto, Ontario, M5G
                          1Z3 Canada
                        </p>
                        <p> +1 6475354940</p>
                      </div>
                      <div className="contact-flex-1">
                        <h5>Alberta:</h5>
                        <p>
                          10180 - 101 Street, Suite 3400, Edmonton, Alberta, T5J
                          3S4 Canada
                        </p>
                        <p> +1 6475354940</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-location-wrap">
                <div className="contact-location-1">
                  <div className="contact-location-1-1">
                    <img src={Location} alt="Location" />
                  </div>
                  <div className="contact-location-1-2">
                    <h4>USA</h4>
                    <p>
                      913 N. Market Street, Suite 200 Wilmington, DE 19801
                      United States
                    </p>
                    <p>+1 302-487-0449</p>
                  </div>
                </div>

                <div className="contact-location-1">
                  <div className="contact-location-1-1">
                    <img src={Location} alt="Location" />
                  </div>
                  <div className="contact-location-1-2">
                    <h4>Dubai</h4>
                    <p>
                      Al Khaimah Building II Office 1F-50, AI Barsha First,
                      Dubai, United Arab Emirates
                    </p>
                    <p>+ 971 564174556</p>
                  </div>
                </div>

                <div className="contact-location-1">
                  <div className="contact-location-1-1">
                    <img src={Location} alt="Location" />
                  </div>
                  <div className="contact-location-1-2">
                    <h4>India</h4>
                    <p>
                      RAM SVR, 2nd Floor HUDA Techno Enclave, Hitec City,
                      Hyderabad, India - 500081
                    </p>
                    <p>+ 91 9642444450</p>
                  </div>
                </div>
                <div className="contact-location-1">
                  <div className="contact-location-1-1">
                    <img src={Location} alt="Location" />
                  </div>
                  <div className="contact-location-1-2">
                    <h4>Malta</h4>
                    <p>12 , J.F Marks Street, San Gwann - Malta</p>
                    <p>+ 356 9999 9323</p>
                  </div>
                </div>
                <div className="contact-location-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
