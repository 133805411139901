const ExpertisesData = {
  FrontOffice: {
    id: "FrontOffice",
    url: "FrontOffice",

    BannerText: "Front Office",
    Heading: "",
    SubBannerText: "Enable your front office to assume leadership.",
    HeadingDescription:
      "Front office systems and processes are encountering unprecedented performance expectations. Equip them with advanced technology and streamlined processes to confidently navigate any investment challenge. Whether pursuing complex multi-asset class investments or entering new markets, we guarantee your front office is poised to lead the way, protected from operational obstacles..",
    Headingdescription2:
      "TechVest Global specializes in empowering financial institutions with robust IT and business solutions tailored for the front office. Our comprehensive approach integrates deep industry knowledge with cutting-edge technology, enabling clients to optimize performance, ensure regulatory compliance, and drive strategic decision-making.",
    content: [
      {
        id: "OMS Implementations",
        haeding: "OMS Implementations",
        subheading: "",
        descritpion:
          "TechVest Global excels in implementing Order Management Systems (OMS) that streamline trade execution, order routing, and portfolio management. Our experienced team supports multiple ",
      },
      {
        id: "Compliance Rule Writing",
        haeding: "Compliance Rule Writing",
        subheading: "",
        descritpion:
          "We offer expertise in compliance rule writing to ensure adherence to regulatory requirements and internal policies. Our solutions automate compliance monitoring, audit trail management, and reporting, enabling financial institutions to mitigate compliance risks effectively and maintain regulatory alignment.",
      },
      {
        id: "Performance and Risk",
        haeding: "Performance and Risk",
        subheading: "",
        descritpion:
          "TechVest Global enhances performance and risk management capabilities through advanced analytics and reporting tools. Our solutions enable front office teams to analyze portfolio performance, assess risk exposures, and optimize investment strategies. By providing actionable insights and customizable dashboards, we empower clients to make informed decisions and achieve superior investment outcomes.",
      },
    ],
  },
  MiddleOffice: {
    id: "MiddleOffice",
    url: "MiddleOffice",
    BannerText: "Middle Office",
    SubBannerText:
      "Power your innovative investment strategy with a transformed middle office technology and operational framework.",
    Heading:
      "Rely on experienced middle office specialists to Support Middle Office Functions",
    HeadingDescription:
      "As the lines blur between front, middle, and back offices, the importance of integrated data, accurate accounting, and efficient workflows grows. Outdated systems, methods, or practices can hinder your operational agility, impacting your ability to meet evolving client needs and capitalize on advanced investment strategies. Drawing on extensive experience in middle office operations, we help you overcome operational challenges and pave the way for future growth with proven technology solutions.",
    Headingdescription2:
      "In the dynamic landscape of the financial industry, the middle office plays a crucial role in ensuring operational efficiency, regulatory compliance, and strategic decision-making. At TechVest Global, we specialize in providing comprehensive IT and business solutions tailored to support middle office functions across diverse financial institutions. Our integrated approach combines deep industry knowledge with advanced technology capabilities, empowering clients to optimize performance, mitigate risks, and drive sustainable growth.",
    content: [
      {
        id: "Post Trade Support",
        haeding: "Post Trade Support",
        subheading: "",
        descritpion:
          "TechVest Global offers robust post-trade support solutions designed to streamline trade confirmation, settlement processing, and reconciliation. Our automated workflows and real-time monitoring tools ensure timely resolution of trade discrepancies and enhance operational efficiency. By integrating with existing systems and leveraging industry best practices, we enable financial institutions to minimize operational risks and improve client satisfaction.",
      },
      {
        id: "OTC Derivatives",
        haeding: "OTC Derivatives",
        subheading: "",
        descritpion:
          "Navigating the complexities of over-the-counter (OTC) derivatives requires specialized expertise and technology solutions. TechVest Global provides tailored OTC derivatives platforms that facilitate trade lifecycle management, valuation, and regulatory reporting. Our solutions incorporate sophisticated analytics and risk management capabilities to optimize derivative trading strategies and ensure compliance with evolving regulatory requirements.",
      },
      {
        id: "Reference Data Management",
        haeding: "Reference Data Management",
        subheading: "",
        descritpion:
          "Effective reference data management is essential for accurate decision-making and regulatory compliance. TechVest Global offers comprehensive reference data solutions that centralize data sources, standardize data formats, and enhance data quality. By implementing robust data governance frameworks and automation tools, we help financial institutions maintain data integrity, improve operational efficiency, and reduce costs associated with data management.",
      },
      {
        id: "IBOR (Investment Book of Record)",
        haeding: "IBOR (Investment Book of Record)",
        subheading: "",
        descritpion:
          "TechVest Global specializes in IBOR solutions that provide a single, accurate view of investment portfolios across asset classes. Our IBOR platforms integrate data from multiple sources, including trading systems and market data providers, to deliver real-time portfolio analytics and performance attribution. By enabling timely and informed investment decisions, we empower asset managers to optimize portfolio returns and enhance client outcomes.",
      },
      {
        id: "Collateral Management",
        haeding: "Collateral Management",
        subheading: "",
        descritpion:
          "Efficient collateral management is critical for managing counterparty risk and optimizing capital efficiency. TechVest Global offers advanced collateral management solutions that automate collateral allocation, margin calculations, and collateral optimization strategies. Our platforms support regulatory compliance and enable financial institutions to minimize collateral costs while maximizing liquidity and operational transparency.",
      },
      {
        id: "Performance Attribution and Analytics",
        haeding: "Performance Attribution and Analytics",
        subheading: "",
        descritpion:
          "TechVest Global enhances performance attribution and analytics capabilities through advanced data analytics and reporting tools. Our solutions enable asset managers to analyze portfolio performance, identify key drivers of returns, and assess investment strategies' effectiveness. By providing actionable insights and customizable reporting dashboards, we empower clients to make informed decisions and demonstrate value to stakeholders.",
      },
      {
        id: "Client Reporting",
        haeding: "Client Reporting",
        subheading: "",
        descritpion:
          "TechVest Global delivers customized client reporting solutions that enhance transparency and client engagement. Our reporting platforms automate report generation, customization, and distribution, ensuring timely delivery of accurate and compliant reports to clients. By integrating with CRM systems and leveraging interactive reporting features, we enable financial institutions to differentiate their service offerings and strengthen client relationships.",
      },
    ],
  },
  BackOffice: {
    id: "BackOffice",
    url: "BackOffice",
    BannerText: "Back Office",
    SubBannerText:
      "Manage risk, optimize resources, and streamline operations with adaptable back office technology and services.",
    Heading: "Ensure efficient back office onboarding and operations.",
    HeadingDescription:
      "To meet the demands of modern markets, you require seamless compliance, reporting, accounting, and custody services. Our team of back office specialists is dedicated to optimizing your service provider relationships and reducing the risk of operational disruptions. By implementing robust back office technology and operations, we enable you to focus on executing a top-tier investment strategy without distractions.",
    Headingdescription2: "",
    content: [
      {
        id: "Custody",
        haeding: "Custody",
        subheading: "",
        descritpion:
          "TechVest Global enhances custodial services with advanced IT solutions tailored for the financial industry. Our custody solutions ensure secure asset custody, efficient transaction processing, and seamless client reporting. By integrating automated workflows and real-time tracking capabilities, we enable custodians to optimize operational efficiency while maintaining regulatory compliance and client trust.",
      },
      {
        id: "Cash Management",
        haeding: "Cash Management",
        subheading: "",
        descritpion:
          "Efficient cash management is critical for financial institutions to optimize liquidity and mitigate risks. TechVest Global offers comprehensive cash management solutions that streamline cash flow forecasting, optimize treasury operations, and enhance liquidity management strategies. Our integrated platforms provide real-time visibility into cash positions, enabling proactive decision-making and ensuring financial stability.",
      },
      {
        id: "Fund Accounting",
        haeding: "Fund Accounting",
        subheading: "",
        descritpion:
          "TechVest Global specializes in fund accounting solutions that cater to diverse investment strategies and regulatory requirements. Our robust platforms automate NAV calculations, reconcile fund transactions, and generate comprehensive financial reports. By leveraging scalable technology and industry expertise, we help asset managers and fund administrators streamline operations, improve accuracy, and ensure compliance with global accounting standards.",
      },
      {
        id: "Insurance Accounting",
        haeding: "Insurance Accounting",
        subheading: "",
        descritpion:
          "Navigating insurance accounting complexities requires precision and compliance. TechVest Global delivers tailored IT solutions for insurance companies, optimizing policy administration, claims processing, and financial reporting. Our platforms integrate actuarial calculations, reserve management, and regulatory reporting, empowering insurers to enhance operational efficiency, mitigate risks, and deliver superior client service.",
      },
      {
        id: "Valuation",
        haeding: "Valuation",
        subheading: "",
        descritpion:
          "Accurate valuation is essential for informed decision-making and regulatory compliance in finance. TechVest Global provides valuation solutions that leverage advanced analytics, market data integration, and valuation models. Our platforms facilitate real-time asset pricing, portfolio valuation, and risk assessment, enabling financial institutions to optimize investment strategies, meet regulatory requirements, and enhance investor confidence.",
      },
      {
        id: "Regulatory Compliance",
        haeding: "Regulatory Compliance",
        subheading: "",
        descritpion:
          "Staying compliant with evolving regulations is a top priority for financial institutions. TechVest Global offers regulatory compliance solutions that encompass regulatory reporting, compliance monitoring, and audit trail management. Our platforms automate compliance workflows, ensure data accuracy, and provide timely updates on regulatory changes, enabling clients to mitigate compliance risks and maintain regulatory alignment.We offer comprehensive AML/KYC (Anti-Money Laundering/Know Your Customer) services tailored to ensure regulatory compliance and mitigate financial risks. Our solutions include robust identity verification, transaction monitoring, and screening against global watchlists. With advanced technology and industry expertise, we help financial institutions and businesses detect and prevent financial crimes while maintaining a seamless customer experience.",
      },
    ],
  },
  DataManagement: {
    id: "DataManagement",
    url: "DataManagement",
    BannerText: "Data Management",
    SubBannerText: "",
    SubBannerText:
      "Drive your investment strategy forward by harmonizing your front, middle, and back office through a comprehensive data management initiative",
    Heading: "",
    HeadingDescription:
      "As the cornerstone of your operations, data integrity is paramount to driving your investment strategy forward efficiently and effectively. Our expert team specializes in diagnosing and remedying common data challenges, ensuring your business operates seamlessly with reliable and precise data. Whether you're revamping an overstressed data architecture or implementing robust data governance protocols, we'll guide you towards achieving your data management objectives throughout the investment lifecycle.",
    Headingdescription2: "",
    content: [
      {
        id: "Governance",
        haeding: "Governance",
        subheading: "",
        descritpion:
          "Effective data governance is paramount in the financial industry. TechVest Global specializes in implementing robust governance frameworks that ensure data integrity, compliance with regulatory standards, and alignment with business objectives. Our tailored solutions encompass data quality management, metadata management, and regulatory reporting, enabling financial institutions to establish clear accountability and transparency across their data lifecycle.",
      },
      {
        id: "Architecture",
        haeding: "Architecture",
        subheading: "",
        descritpion:
          "TechVest Global designs scalable and agile data architectures tailored to the unique needs of financial institutions. From cloud-based data lakes to hybrid data integration platforms, our solutions optimize data storage, processing, and accessibility. By leveraging industry best practices and advanced technologies, we empower clients to harness the full potential of their data assets while maintaining security, scalability, and performance.",
      },
      {
        id: "Information Delivery",
        haeding: "Information Delivery",
        subheading: "",
        descritpion:
          "Timely and accurate information delivery is critical for informed decision-making in finance. Our information delivery solutions include real-time data analytics dashboards, self-service reporting tools, and personalized insights platforms. By integrating data visualization and advanced analytics capabilities, we enable stakeholders to gain actionable insights, monitor key performance indicators, and drive business growth with confidence.",
      },
      {
        id: "Business Intelligence with AI",
        haeding: "Business Intelligence with AI",
        subheading: "",
        descritpion:
          "TechVest Global integrates artificial intelligence (AI) with business intelligence (BI) to unlock predictive insights and drive strategic decision-making in finance. Our AI-powered solutions encompass machine learning algorithms, predictive modeling, and natural language processing (NLP). By automating data analysis, detecting patterns, and forecasting trends, we empower financial institutions to optimize risk management, enhance customer experiences, and capitalize on market opportunities.",
      },
    ],
  },
  Alternative: {
    id: "Alternative",
    url: "Alternative",
    BannerText: "Alternatives",

    SubBannerText:
      "Work together with experts to pinpoint the optimal operational model for your alternative investment strategy.",
    Heading: "",
    HeadingDescription:
      "Harnessing the Power of Alternative Assets: A Comprehensive IT and Business Perspective",
    Headingdescription2: "",
    content: [
      {
        id: "Hedge Funds and Fund of Funds",
        haeding: "Hedge Funds and Fund of Funds",
        subheading: "",
        descritpion:
          "TechVest Global excels in providing tailored IT and business solutions for hedge funds and fund of funds. Leveraging advanced analytics and robust technology frameworks, we empower clients to optimize fund performance, enhance risk management capabilities, and streamline operations. From dynamic portfolio management systems to real-time reporting dashboards, our solutions are designed to meet the unique needs of alternative asset managers, ensuring agility and compliance in a rapidly changing market landscape.",
      },
      {
        id: "Private Equity",
        haeding: "Private Equity",
        subheading: "",
        descritpion:
          "Navigating the complexities of private equity investments requires precision and foresight. At TechVest Global, we offer bespoke IT solutions that cater to the specific demands of private equity firms. Our comprehensive suite of services includes deal sourcing platforms, due diligence tools, and investor relations management systems. By integrating data-driven insights and scalable technology solutions, we enable clients to make informed investment decisions, drive operational efficiencies, and achieve superior returns.",
      },
      {
        id: "Structured Loans",
        haeding: "Structured Loans",
        subheading: "",
        descritpion:
          "TechVest Global supports financial institutions in managing structured loan portfolios with precision and efficiency. Our specialized IT solutions encompass loan origination systems, credit risk analytics, and portfolio management platforms. By leveraging automation and predictive analytics, we help lenders streamline loan processing, mitigate credit risks, and enhance portfolio performance. Whether managing complex loan structures or optimizing loan servicing operations, our solutions are tailored to meet the evolving needs of the financial industry.",
      },
      {
        id: "Real Estate",
        haeding: "Real Estate",
        subheading: "",
        descritpion:
          "In the dynamic realm of real estate investments, TechVest Global provides robust IT and business solutions that empower stakeholders to maximize asset performance and mitigate operational risks. From property management platforms to real estate valuation tools, our solutions facilitate seamless property transactions, lease management, and asset portfolio optimization. By integrating data analytics and IoT technologies, we enable real estate investors and property managers to make informed decisions, enhance tenant experiences, and achieve sustainable growth.",
      },

      {
        id: "Partner with TechVest Global",
        haeding: "Partner with TechVest Global",
        subheading: "",
        descritpion:
          "Partner with TechVest Global to capitalize on the transformative potential of alternative assets. With our deep industry expertise, innovative technology solutions, and commitment to client success, we empower financial institutions to thrive in a competitive marketplace. Contact us today to explore how our customized IT and business solutions can accelerate your growth and achieve your strategic objectives in alternative asset management.",
      },
    ],
  },
  Outsourcing: {
    id: "Outsourcing ",
    url: "Outsourcing ",
    BannerText: "Outsourcing",
    SubBannerText:
      "Craft and deploy a superior operating framework with unparalleled outsourcing proficiency.",
    Heading: "To synchronize an operational model shift",
    HeadingDescription:
      "comprehending your needs, available outsourcing choices, and transition procedures is crucial. We'll assist you in evaluating areas where outsourcing can optimize operational efficiency and orchestrate the entire process, from vendor assessment to post-transition assistance. With over 40 successful outsourcing transitions, we possess extensive knowledge of the vendor landscape and decades of experience aligning asset managers' requirements with service provider capacities.",
    content: [
      {
        id: "Full Outsourcing",
        haeding: "Full Outsourcing",
        subheading:
          "Transitioning your middle or back office functions to a third-party provider?",
        descritpion:
          "Choosing the right long-term partner is paramount when reshaping your operational framework. You require a reliable outsourcing ally equipped with adaptable technology to grow alongside your investment approach and deliver impeccable service. We'll assist you in evaluating whether full outsourcing aligns with your business objectives and identify a partner capable of seamlessly integrating into your team.",
      },
      {
        id: "Component Outsourcing",
        haeding: "Component Outsourcing",
        subheading:
          "Enhance your operational efficiency by strategically outsourcing specific functions.",
        descritpion:
          " When your front office demands agile responses for intricate investment strategies, your operations must seamlessly support these maneuvers. Identifying operational bottlenecks enables you to devise an outsourcing plan that optimizes your capabilities and primes your team for swift adaptability. Our expertise lies in evaluating your operational structure to identify ideal functions for outsourcing. Leveraging our extensive vendor knowledge, we'll pinpoint the service provider best equipped to maximize your technological resources and operational agility.",
      },
      {
        id: "Service Provider Outsourcing Transitions",
        haeding: "Service Provider Outsourcing Transitions",
        subheading: "Effortlessly navigate the transition of your operations.",
        descritpion:
          "Incorporating a third party seamlessly into your operational framework demands trust and resources. Without adequate support, the process can consume valuable time and energy, yielding disjointed data streams or redundant functions without tangible efficiency gains. Our expertise lies in delicately balancing your needs with those of your service provider, ensuring swift and effective achievement of your objectives. Whether you're outsourcing or providing outsourcing services, we draw upon our unparalleled experience across transitions of all scales and complexities to facilitate a seamless integration process.",
      },
    ],
  },
};

export default ExpertisesData;
