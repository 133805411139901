import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import ExpertisesData from "./ExpertiesData";

function Expertise() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  const FrontOffice = ExpertisesData.FrontOffice;
  const BackOffice = ExpertisesData.BackOffice;
  const MiddleOffice = ExpertisesData.MiddleOffice;
  const DataManagment = ExpertisesData.DataManagement;
  const AlternativeInvestment = ExpertisesData.Alternative;
  const outsourcing = ExpertisesData.Outsourcing;


  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>
            We possess comprehensive knowledge of your business from start to
            finish
          </h1>
          <p>
          We have a deep understanding of your business across all stages. When undertaking complex projects that span global borders in the investment sector, you need professionals who are fluent in the language of expertise. At Techvest Global, we partner with leading service providers, custodians, and specialized vendors to navigate the complexities of outsourcing, from suitability assessment to transition

          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>
            Our consultants are well-versed in Banking and Financial Services.


            </h1>
            <p>
            Our consultants are in range of 2 years to 25+ industry experience, spanning various roles across the investment management lifecycle. Partnering with Techvest Global grants you access to experts proficient in every aspect of your business. This ensures accelerated project initiation and enhanced insights, without any loss in communication.

            </p>
          </div>
        </div>
      </div>
      <div className="inner-section-2-bg">
        <div className="wrapper-1">
          <div className="services-card2-section">
            <div className="services-card2-1">
              <h3>Front Office</h3>
              <p>
              Diversified investment strategies, emerging financial products, and global risk management drive your business forward. Our specialty lies in positioning your front office at the forefront, transforming complex investment challenges into innovative technological solutions.

              </p>

              <NavLink
                // to="/Advisory-Service"
                //to= {`/Markets-Served/${AssetOwnerData.id}`}
                to = {`/Experties/${FrontOffice.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Middle Office</h3>
              <p>
              The middle office is the core of your operations. You need a partner capable of understanding your unique operational challenges and enhancing your middle office strategy to achieve new levels of success.


              </p>
              <NavLink
               to = {`/Experties/${MiddleOffice.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Back Office</h3>
              <p>
              From compliance to custody, we have a profound understanding of the back office. Whether you require assistance with onboarding, system rationalization, or operational transformation, we are here to provide support.

              </p>
              <NavLink
              to = {`/Experties/${BackOffice.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                {" "}
                Read More{" "}
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Data Management</h3>
              <p>
              Harness data's power. From governance to analytics, we optimize your data lifecycle for informed decisions and regulatory compliance, empowering your business with actionable insights.
              </p>
              <NavLink
                 to = {`/Experties/${DataManagment.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                {" "}
                Read More{" "}
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Outsourcing</h3>
              <p>
                We collaborate with leading service providers, custodians, and
                specialized vendors to unravel the intricacies of outsourcing,
                from suitability assessment to transition.
              </p>
              <NavLink
                to = {`/Experties/${outsourcing.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                {" "}
                Read More{" "}
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Alternative Investments</h3>
              <p>
              Unlock growth. Navigate complexities with our expertise in hedge funds, private equity, and more. Tailored solutions drive returns and mitigate risks in dynamic markets.


              </p>
              <NavLink
                to = {`/Experties/${AlternativeInvestment.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                {" "}
                Read More{" "}
              </NavLink>
            </div>

            {/* <div className="services-card2-1">
              <h3></h3>
              <p></p>
              <NavLink  to="/Delivery-Service" className="button-2"  onClick={scrollToTop} > Read More </NavLink>
            </div> */}
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expertise;
