import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import { Link } from "react-router-dom";

function AssessmentSelectionOutsourcingSolutions() {
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Assessment and Selection of Outsourcing Solutions</h1>
          <p>
            Partner with industry-leading outsourcing experts to align your
            business with the best service provider available.
          </p>
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <Link to="/Advisory-Service">Consulting Services</Link>
            <a href="#">Assessment and Selection of Outsourcing Solutions</a>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="services-section-wrap text-left">
            <div className="services-section-1">
              <div className="services-section-text1">
                <h1>
                  <span className="black-color">Assemble a Winning Team</span>
                </h1>
                <p>
                  Selecting the right outsourcing partners is crucial for
                  financial asset managers aiming to optimize their operations.
                  TechVest helps you assemble a winning team by leveraging our
                  extensive network and industry expertise. We identify top-tier
                  outsourcing providers that align with your strategic
                  objectives, ensuring that you have the best talent supporting
                  your business.
                </p>
              </div>
            </div>
            <div className="services-section-2">
              <div className="services-section-2-wrap">
                <h3>Streamline the Process</h3>
                {/* <h6>
                  Accelerate your journey to the perfect solution by asking the
                  right questions upfront.
                </h6> */}
                <p>
                  Efficiency is key to successful outsourcing. Our streamlined
                  approach simplifies the assessment and selection process,
                  saving you time and resources. TechVest employs proven
                  methodologies and advanced frameworks to evaluate potential
                  partners, ensuring a smooth and efficient process from start
                  to finish. We handle the complexities so you can focus on core
                  business activities.
                </p>
              </div>
              <div className="services-section-2-wrap">
                <h3>Gain Comprehensive Insights</h3>
                {/* <h6>Explore all avenues to discover the ideal match.</h6> */}
                <p>
                  Informed decisions are based on comprehensive insights.
                  TechVest conducts thorough analyses to provide you with
                  detailed information on potential outsourcing providers. We
                  assess their capabilities, performance history, and market
                  reputation, giving you a clear understanding of each option.
                  Our insights empower you to make strategic choices that drive
                  success.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Keep operations running smoothly</h3>
                {/* <h6>Concentrate on what you do best.</h6> */}
                <p>
                  Maintaining smooth operations during the transition to
                  outsourcing is critical. TechVest ensures that your day-to-day
                  activities remain unaffected by managing the integration
                  process meticulously. We work closely with you to develop
                  transition plans that minimize disruptions and maintain
                  operational continuity, allowing your business to thrive even
                  during periods of change.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Embrace tailored solutions</h3>
                {/* <h6>Dare to be different.</h6> */}
                <p>
                  Every financial asset management firm has unique needs.
                  TechVest offers tailored outsourcing solutions that address
                  your specific challenges and opportunities. We customize our
                  approach to meet your requirements, ensuring that the selected
                  outsourcing partners provide services that enhance your
                  operations and align with your strategic goals.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Master the details</h3>
                {/* <h6>Navigate outsourcing contracts with finesse.</h6> */}
                <p>
                  Success in outsourcing lies in mastering the details. TechVest
                  pays meticulous attention to every aspect of the assessment
                  and selection process. From contractual agreements to
                  performance metrics, we ensure that all elements are
                  thoroughly considered and managed. Our detail-oriented
                  approach guarantees that your outsourcing partnerships are
                  robust and effective.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div class="text-center">
            <a href="/Advisory-Service" class="button-3">
              Back to Consulting Services
            </a>
          </div>
          <div className="line-2"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>

            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentSelectionOutsourcingSolutions;
