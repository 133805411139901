import React from "react";
import "./Footer.css";
import Logo from "../images/logo.png";
import Linkedin from "../images/linkedin.svg";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer">
      <div className="wrapper-1">
        <div className="footer-new-wrap">
          <div className="footer-new-1">
            <h4>Quick Links</h4>
            <ul className="w-50-50">
              <li>
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/Advisory-Service" onClick={scrollToTop}>
                  Consulting Service
                </Link>
              </li>
              <li>
                <Link to="/Delivery-Service" onClick={scrollToTop}>
                  Delivery Services
                </Link>
              </li>
              <li>
                <Link to="/social-responsibility" onClick={scrollToTop}>
                  Social Responsibility
                </Link>
              </li>
              <li>
                <Link to="/events" onClick={scrollToTop}>
                  Events
                </Link>
              </li>

              <li>
                <Link to="/Contact-Us" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-new-2">
            <h4>Markets Served</h4>
            <ul className="w-50-50">
              <li>
                <Link to="/Markets-Served/AssetOwners" onClick={scrollToTop}>
                  Asset Owners
                </Link>
              </li>
              <li>
                <Link to="/Markets-Served/Institutional" onClick={scrollToTop}>
                  Institutional
                </Link>
              </li>
              <li>
                <Link to="/Markets-Served/Insurance" onClick={scrollToTop}>
                  Insurance
                </Link>
              </li>
              <li>
                <Link to="/Markets-Served/PrivateMarket" onClick={scrollToTop}>
                  Private Markets
                </Link>
              </li>
              <li>
                <Link to="/Markets-Served/Retail" onClick={scrollToTop}>
                  Retail
                </Link>
              </li>

              <li>
                <Link to="/Markets-Served/Wealth" onClick={scrollToTop}>
                  Wealth
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-new-3">
            <h4>Expertise</h4>
            <ul className="w-50-50">
              <li>
                <Link to="/Experties/FrontOffice" onClick={scrollToTop}>
                  Front Office
                </Link>
              </li>
              <li>
                <Link to="/Experties/MiddleOffice" onClick={scrollToTop}>
                  Middle Office
                </Link>
              </li>
              <li>
                <Link to="/Experties/BackOffice" onClick={scrollToTop}>
                  Back Office
                </Link>
              </li>
              <li>
                <Link to="/Experties/DataManagement" onClick={scrollToTop}>
                  Data Management
                </Link>
              </li>
              <li>
                <Link to="/Experties/Alternative" onClick={scrollToTop}>
                  Alternatives
                </Link>
              </li>

              <li>
                <Link to="/Experties/Outsourcing" onClick={scrollToTop}>
                  Outsourcing
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="footer-logo">
          <NavLink to="/">
            <img src={Logo} alt="Logo" />
          </NavLink>
        </div> */}
        {/* <div className="footer-nav">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>

            <li>
              <NavLink to="/Services">Services</NavLink>
            </li>

            <li>
              <NavLink to="/Markets-Served">Markets Served</NavLink>
            </li>

            <li>
              <NavLink to="/Expertise">Expertise</NavLink>
            </li>

            <li>
              <NavLink to="/About">About</NavLink>
            </li>

            <li>
              <NavLink to="/Insights">Insights</NavLink>
            </li>

            <li>
              <NavLink to="/Careers">Careers</NavLink>
            </li>

            <li>
              <NavLink to="/Contact-Us">Contact Us</NavLink>
            </li>
          </ul>
        </div> */}
        <div className="footer-copy-wrap">
          <div className="footer-copy-1">
            &copy; 2024. TECHVEST GLOBAL. All Rights Reserved.
          </div>
          <div className="footer-copy-2">
            <img src={Linkedin} alt="Linkedin" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
