import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/About.css";
import Managementteam from "../../images/management-team.png";
import Linkedin from "../../images/linkedin.png"
import communityImages from "../../images/Community Engagement and Empowerment.jpg"
import diversity from "../../images/Diversity, Equity, and Inclusion.jpg"
import employeeangment from "../../images/Employee Engagement and Volunteerism.jpg"
import sustanibility from "../../images/Environmental Sustainability.jpg"
import ethical from "../../images/Ethical Business Practices.jpg"
import joinus from "../../images/Join Us in Making a Difference.jpg"
import philanthropy from "../../images/Philanthropy and Giving Back.jpg"
import transparency from "../../images/Transparency and Reporting.jpg"
// import Managementteam from "../images/management-team.png";
// import Linkedin from "../../images/linkedin.png";

function SocialResponsibility() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Social Responsibility</h1>
        </div>
      </div>



      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>
            Embracing Social Responsibility at TechVest Global
            </h1>
            <p>
            At TechVest Global, we are committed to making a positive impact through our social responsibility initiatives. We believe in leveraging our resources, expertise, and influence to drive meaningful change in the communities where we live and work. Our commitment extends across several key areas:
            </p>
          </div>
        </div>
      </div>
      {/* <div className="team-sec1-bg">
        <div className="wrapper-1">
          <div className="social-content-list">
            <h4>Align Operations with Your Multi-Asset Class Strategies</h4>
            <h5>
              Craft an operational framework that aligns with your portfolio
              management objectives.
            </h5>
            <p>
              Achieving your investment goals requires advanced investment
              strategies. If your focus includes private equity, private debt,
              real estate, infrastructure, or credit assets, you confront
              distinct operational challenges in transaction processing,
              investment valuation, and risk and performance monitoring. To
              mitigate operational costs and risks while enhancing data
              accessibility, it's crucial to develop a scalable model tailored
              to your needs. Whether you require a comprehensive assessment of
              your technology architecture or a targeted evaluation of your data
              management capabilities, our proven strategic assessment
              methodology will guide you towards the optimal operating model and
              a practical roadmap to realize your envisioned target state.
            </p>
          </div>
          <div className="social-content-list">
            <h4>Address the Requirements of Your End Investors</h4>
            <h5>
              Evaluate and pinpoint solutions to fulfill your investor
              commitments.
            </h5>
            <p>
              As stewards of your organization's endowment or the future
              retirement of beneficiaries, the stakes are high. Your end
              investors demand investment clarity and a more personalized
              approach to portfolio management. Leveraging our profound
              understanding of the vendor landscape, we assist you in
              identifying optimal solutions to meet your diverse investment
              objectives, selecting reporting solutions to provide transparency
              to your end investors, and establishing operational infrastructure
              to deliver timely and actionable data for enhanced investment
              decision-making.
            </p>
          </div>
        </div>
      </div> */}

<div className="two-col-main card-sec text-left">
            <div className="two-col-1">
              <img src={communityImages} alt="communityImages" />
              <h3>Community Engagement and Empowerment</h3>
              <p>
              We actively engage with local communities to understand their needs and contribute to their development. Through partnerships with nonprofits and community organizations, as well as employee volunteerism, we support initiatives that promote education, healthcare access, and economic empowerment.
              </p>
              
            </div>

            <div className="two-col-1">
              <img
                src={sustanibility}
                alt="sustanibility"
              />
              <h3>Environmental Sustainability              </h3>
              <p>
              TechVest Global is dedicated to environmental stewardship. We integrate sustainable practices into our operations to minimize our ecological footprint. This includes initiatives to reduce energy consumption, manage waste responsibly, and promote environmentally friendly practices throughout our supply chain.
              </p>
             
            </div>
            <div className="two-col-1">
              <img src={diversity} alt="diversity" />
              <h3>Diversity, Equity, and Inclusion              </h3>
              <p>
              Diversity is fundamental to our success and innovation. We foster an inclusive workplace where employees of diverse backgrounds feel valued and respected. Our commitment to diversity extends beyond our workforce to our partnerships and community engagements, promoting equality and equity in all aspects of our business.

              </p>
             
            </div>

            <div className="two-col-1">
              <img src={ethical} alt="ethical" />
              <h3>Ethical Business Practices</h3>
              <p>
              Integrity and ethical behavior are at the core of everything we do at TechVest Global. We uphold the highest standards of business ethics and integrity in our interactions with clients, partners, and stakeholders. Transparency and accountability are key principles that guide our decision-making processes.

              </p>
            
            </div>
            <div className="two-col-1">
              <img src={philanthropy} alt="philanthropy" />
              <h3>Philanthropy and Giving Back
              </h3>
              <p>
              Giving back is a cornerstone of our corporate philosophy. We support charitable organizations and initiatives that address critical societal issues, such as education, healthcare, social justice, and disaster relief. Through financial contributions, employee volunteerism, and strategic partnerships, we aim to make a meaningful impact on global and local communities.

              </p>
            
            </div>
            <div className="two-col-1">
              <img src={employeeangment} alt="employeeangment" />
              <h3>Employee Engagement and Volunteerism
              </h3>
              <p>
              Our employees are passionate about making a difference. We encourage and support their involvement in social responsibility initiatives through volunteer opportunities, fundraising campaigns, and skills-based volunteering. By empowering our team to contribute their time and talents, we foster a culture of giving and civic responsibility.

              </p>
            
            </div>
            <div className="two-col-1">
              <img src={transparency} alt="transparency" />
              <h3>Transparency and Reporting
              </h3>
              <p>
              We are committed to transparency in our social responsibility efforts. We regularly measure and report on our progress, initiatives, and impact to stakeholders. By sharing our successes, challenges, and lessons learned, we invite collaboration and feedback to continuously improve our social responsibility practices.

              </p>
            
            </div>
            <div className="two-col-1">
              <img src={joinus} alt="joinus" />
              <h3>Join Us in Making a Difference
              </h3>
              <p>
              At TechVest Global, social responsibility is not just a commitment; it's a fundamental part of our corporate identity. We invite our stakeholders, clients, and partners to join us in creating a positive impact on society and the environment. Together, we can build a brighter future for everyone.
              </p>
            
            </div>
          </div>


      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="line-3"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialResponsibility;
