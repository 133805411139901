import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import { Link } from "react-router-dom";

function StrategicEvaluation() {
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Strategic Evaluation</h1>
          <p>
            Optimize Your Financial Asset Management with TechVest's Strategic
            Evaluation Services Partner with industry experts to gain critical
            insights, enhance efficiency, and achieve your goals. Discover
            practical solutions and liberate your resources for sustained
            success.
          </p>
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <Link to="/Advisory-Service">Consulting Services</Link>
            <a href="#">Strategic Evaluation</a>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="services-section-wrap text-left">
            <div className="services-section-1">
              <div className="services-section-text1">
                <h1>
                  <span className="black-color">
                    Partner with a Trusted Advisor
                  </span>
                </h1>
                <p>
                  Choosing the right partner is critical for optimizing your
                  operations. TechVest, with decades of industry experience, is
                  your trusted advisor, guiding you through the complexities of
                  financial asset management.
                </p>
              </div>
            </div>
            <div className="services-section-2">
              <div className="services-section-2-wrap">
                <h3>Gain Critical Insights </h3>
                {/* <h6>
                  Discover how your industry peers tackle their technology and
                  operational obstacles.
                </h6> */}
                <p>
                  Our Strategic Evaluation Services provide deep, actionable
                  insights into market trends and performance benchmarks. With
                  our advanced analytics and extensive expertise, we equip you
                  with the knowledge to make informed decisions that drive
                  success.
                </p>
              </div>
              <div className="services-section-2-wrap">
                <h3>Optimize Operational Efficiency</h3>
                {/* <h6>
                  Discover avenues for cost reduction across your enterprise.
                </h6> */}
                <p>
                  Efficiency is crucial in maintaining a competitive edge. Our
                  consultants identify and eliminate inefficiencies within your
                  processes, ensuring streamlined and cost-effective operations
                  that enhance productivity and profitability.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Free Up Valuable Resources</h3>
                {/* <h6>
                  A forward-thinking strategy begins with the appropriate
                  resources.
                </h6> */}
                <p>
                  By optimizing your systems and processes, we help you liberate
                  valuable resources, allowing you to focus on core business
                  activities and strategic initiatives. This redirection of
                  resources boosts your overall performance and growth.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Implement Practical Solutions</h3>
                {/* <h6>
                  Simplify Complexity with a Reliable Assessment Methodology.
                </h6> */}
                <p>
                  We believe in practical, results-driven strategies. Our team
                  collaborates closely with you to implement solutions that are
                  both effective and feasible, ensuring smooth transitions and
                  sustained improvements tailored to your unique needs.
                </p>
              </div>

              <div className="services-section-2-wrap">
                <h3>Enhance Communication Flow</h3>
                {/* <h6>
                Effective communication is fundamental to your strategy, from stakeholders to senior leadership.
                </h6> */}
                <p>
                  Effective communication is vital for organizational success.
                  We improve your communication channels and protocols, ensuring
                  seamless information flow across your organization. This
                  fosters a cohesive, well-informed team ready to tackle any
                  challenge.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div class="text-center">
            <a href="/Advisory-Service" class="button-3">
              Back to Consulting Services
            </a>
          </div>
          <div className="line-2"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>

            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrategicEvaluation;
