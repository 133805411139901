import React from "react";
import { NavLink } from "react-router-dom";
import blogOne from "../../images/resources-1.png";
import blogTwo from "../../images/resources-2.png";
import blogThree from "../../images/resources-3.png";
import blogFour from "../../images/resources-4.png";

function Resources() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Resources</h1>
        </div>
      </div>
      <h1 className="text-center black-color">
        <br />
        content will be posted soon..
      </h1>
      {/* <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="two-col-main card-sec text-left">
            <div className="two-col-1">
              <img src={blogOne} alt="StrategicEvaluation" />
              <div className="blog-title-two-col">
                <div className="blog-title-two-col-1">
                  <h4>Lorem Ipsum</h4>
                </div>
                <div className="blog-title-two-col-2">
                  <h6>Jun 05, 2024</h6>
                </div>
              </div>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <NavLink
                to="/Strategic-Evaluation"
                className="button-line"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="two-col-1">
              <img src={blogTwo} alt="StrategicEvaluation" />
              <div className="blog-title-two-col">
                <div className="blog-title-two-col-1">
                  <h4>Lorem Ipsum</h4>
                </div>
                <div className="blog-title-two-col-2">
                  <h6>Jun 05, 2024</h6>
                </div>
              </div>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <NavLink
                to="/Strategic-Evaluation"
                className="button-line"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="two-col-1">
              <img src={blogThree} alt="StrategicEvaluation" />
              <div className="blog-title-two-col">
                <div className="blog-title-two-col-1">
                  <h4>Lorem Ipsum</h4>
                </div>
                <div className="blog-title-two-col-2">
                  <h6>Jun 05, 2024</h6>
                </div>
              </div>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <NavLink
                to="/Strategic-Evaluation"
                className="button-line"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="two-col-1">
              <img src={blogFour} alt="StrategicEvaluation" />
              <div className="blog-title-two-col">
                <div className="blog-title-two-col-1">
                  <h4>Lorem Ipsum</h4>
                </div>
                <div className="blog-title-two-col-2">
                  <h6>Jun 05, 2024</h6>
                </div>
              </div>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <NavLink
                to="/Strategic-Evaluation"
                className="button-line"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>
          </div>
        </div>
      </div> */}
      <div className="wrapper-1">
        <div className="line-2"></div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <a href="#" className="button-3">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
