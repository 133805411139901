import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "../../styles/About.css";
import Managementteam from "../../images/management-team.png";
import Linkedin from "../../images/linkedin.png";
import OpportunitiesInnerdata from "./opportunitiesInnerData";

function OpportunitiesInner() {
  const [JobDetails, setJobDetails] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [jobtitle, setJobTitle] = useState("");
  const [Region, setRegion] = useState("");
  const [Message, setMessage] = useState("");

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const { id } = useParams();
  const jobId = parseInt(id);

  useEffect(() => {
    const fetchCareerData = async () => {
      try {
        const response = await fetch(
          `https://techvestglobal.com/api/careerdetail.php/${jobId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jobData = await response.json();
        if (jobData) {
          setJobDetails(jobData); // Set the fetched job details
          setJobTitle(jobData.job_title); // Initialize job title
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCareerData();
  }, [jobId]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!firstName.trim()) {
      newErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      valid = false;
    }

    if (!Email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }

    if (!PhoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required";
      valid = false;
    }

    if (!CompanyName.trim()) {
      newErrors.companyName = "Company Name is required";
      valid = false;
    }

    if (!jobtitle.trim()) {
      newErrors.jobTitle = "Job Title is required";
      valid = false;
    }

    if (!Region.trim()) {
      newErrors.region = "Region is required";
      valid = false;
    }

    if (!Message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    if (!recaptchaValue) {
      newErrors.recaptcha = "Please complete the reCAPTCHA";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const onSubmitCarrerFormData = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      console.log("Form has errors, cannot submit");
      return;
    }

    const formData = {
      firstname: firstName,
      lastname: lastName,
      email: Email,
      phone: PhoneNumber,
      job_id: jobId,
      job_title: jobtitle,
      companyname: CompanyName,
      region: Region,
      message: Message,
      recaptcha: recaptchaValue,
    };

    try {
      const response = await fetch(
        "https://techvestglobal.com/api/career-form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data1 = await response.json();
      console.log("response from post", data1);

      // Clear form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setCompanyName("");
      setRegion("");
      setMessage("");
      setRecaptchaValue(null);
      setSuccessMessage("Form submitted successfully!");
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error state or show error message to user
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  if (!JobDetails) {
    return <div>Job not found</div>;
  }

  return (
    <div>
      <div className="inner-banner">
        <img
          src={`https://techvestglobal.com/uploads/${JobDetails.banner_img}`}
          alt={JobDetails.job_title}
        />
      </div>

      <div className="team-sec1-bg">
        <div className="wrapper-1">
          <div>
            <div className="opp-sub-card wrapper-1" style={{ margin: "50px" }}>
              <div>
                <h3>{JobDetails.job_title}</h3>
                {/* <p>{JobDetails.description}</p> */}
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: JobDetails.requirements,
                }}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: JobDetails.description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="/Careers/CareerOpportunities" class="button-3">
          Back to Opportunities
        </a>
      </div>
      {/* Apply Job form */}
      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <div className="our-vendor-relationship-form">
              <h3>
                Want to Learn More? Get in Touch! Reach out to us to discuss
                your objectives or to discover further insights into our
                collaboration with vendors.
              </h3>

              <div className="our-vendor-relationship-form-flex">
                <form>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    {errors.firstName && (
                      <span className="error">{errors.firstName}</span>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    {errors.lastName && (
                      <span className="error">{errors.lastName}</span>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Email"
                      name="Email"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Phone Number"
                      name="PhoneNumber"
                      value={PhoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                    {errors.phoneNumber && (
                      <span className="error">{errors.phoneNumber}</span>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="CompanyName"
                      value={CompanyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />
                    {errors.companyName && (
                      <span className="error">{errors.companyName}</span>
                    )}
                  </div>
                  <div className="ff-1">
                    <input
                      type="text"
                      placeholder="Job Title"
                      name="jobtitle"
                      value={jobtitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      required
                    />
                    {errors.jobTitle && (
                      <span className="error">{errors.jobTitle}</span>
                    )}
                  </div>
                  <div className="ff-1 w-100">
                    <input
                      type="text"
                      placeholder="Region"
                      className=""
                      name="Region"
                      value={Region}
                      onChange={(e) => setRegion(e.target.value)}
                      required
                    />
                    {errors.region && (
                      <span className="error">{errors.region}</span>
                    )}
                  </div>
                  <div className="ff-1 w-100">
                    <textarea
                      placeholder="Message"
                      name="Message"
                      value={Message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                    {errors.message && (
                      <span className="error">{errors.message}</span>
                    )}
                  </div>
                  <div className="ff-1 w-100">
                    <ReCAPTCHA
                      sitekey="6LeXOhgqAAAAAGmIwrebp2O0CQlEifNx2gV--D1S"
                      onChange={onRecaptchaChange}
                    />
                    {errors.recaptcha && (
                      <span className="error">{errors.recaptcha}</span>
                    )}
                  </div>
                  <div className="ff-1 w-100 text-left">
                    <b>Read Our Privacy Policy Here</b>
                  </div>
                  <div className="ff-1 w-100 text-left">
                    <button
                      className="button-3"
                      onClick={onSubmitCarrerFormData}
                    >
                      Submit
                    </button>
                    {successMessage && (
                      <div className="success-message">{successMessage}</div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpportunitiesInner;
