import React from "react";
import { NavLink } from "react-router-dom";
//import StrategicEvaluation from "../../Components/StrategicEvaluation";
import StrategicEvaluation from "../../images/strategic-evaluation.png";
import BenchmarkingSolutions from "../../images/benchmarking-solutions.png";
import AssessmentSelection from "../../images/assessment-and-selection-of-outsourcing-solutions.png";
import StrategySelectionSystems from "../../images/strategy-and-selection-of-systems.png";
// import StrategicEvaluation from "../images/strategic-evaluation.png";
// import BenchmarkingSolutions from "../images/benchmarking-solutions.png";
// import AssessmentSelection from "../images/assessment-and-selection-of-outsourcing-solutions.png";
// import StrategySelectionSystems from "../images/strategy-and-selection-of-systems.png";

function AdvisoryService() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Consulting Services</h1>
          <p>
            Enhance your technology and operational strategy with an experienced
            partner at your side
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>
              Gain a holistic understanding of your business and the industry
              landscape from all perspectives.
            </h1>
            <p>
              Allow us to assist you in visualizing your optimal operating model
              and charting a course towards its realization. Backed by our
              unparalleled insight into trends and industry standards, we are
              your ideal partner to navigate your journey.
            </p>
          </div>
          <div className="two-col-main card-sec text-left">
            <div className="two-col-1">
              <img src={StrategicEvaluation} alt="StrategicEvaluation" />
              <h3>Strategic Evaluation</h3>
              <p>
                Let us help you envision your optimal operating model and map
                out a path to achieve it. With our unmatched insight into trends
                and industry standards, we are the perfect partner to guide you
                on your journey.
              </p>
              <NavLink
                to="/Strategic-Evaluation"
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="two-col-1">
              <img
                src={StrategySelectionSystems}
                alt="StrategySelectionSystems"
              />
              <h3>Strategy and Selection of Systems</h3>
              <p>
                Reaching your desired future state demands the right systems.
                Our consultants, with their extensive expertise across various
                applications in the investment continuum, can help you build the
                optimal toolkit to achieve your goals.
              </p>
              <a href="/Strategy-Selection-System" className="button-2">
                Read More
              </a>
            </div>
            <div className="two-col-1">
              <img src={AssessmentSelection} alt="AssessmentSelection" />
              <h3>Assessment and Selection of Outsourcing Solutions</h3>
              <p>
                Successfully navigating outsourcing processes and technology
                requires a nuanced understanding of available options. Through
                our extensive partnerships with leading outsourcing providers,
                we offer unmatched insights into managing outsourcing projects
                of all sizes.
              </p>
              <a
                href="/Assessment-Selection-Outsourcing-Solutions"
                className="button-2"
              >
                Read More
              </a>
            </div>

            <div className="two-col-1">
              <img src={BenchmarkingSolutions} alt="BenchmarkingSolutions" />
              <h3>Benchmarking Solutions</h3>
              <p>
                Deciding whether to chart a new course or stay on your current
                path? Our Benchmarking Solutions offer the insights needed for
                informed decision-making. With decades of expertise in asset
                management software and services, we enable swift and
                cost-effective benchmarking of your current provider against
                market standards.
              </p>
              <a href="/Benchmarking-Solutions" className="button-2">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper-1">
        <div className="line-2"></div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <a href="#" className="button-3">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvisoryService;
