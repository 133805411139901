import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import AboutData from "./AboutData"; // Ensure this path is correct

function About() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const data = AboutData.About;

  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>{data.BannerText}</h1>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>{data.Heading}</h1>
            <p>{data.HeadingDescription}</p>
          </div>
        </div>
      </div>

      <div className="inner-section-3-bg">
        <div className="wrapper-1">
          {data.content.map((item) => (
            <div key={item.id} className="ms-content-list">
              <h4>{item.haeding}</h4>
              {item.subheading && <h5>{item.subheading}</h5>}
              <p>{item.descritpion}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="line-3"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
