import "./App.css";
import Navbar from "./Header/Navbar";
import Footer from "./Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Services from "./Service/Services";
import AdvisoryService from "./Service/AdvisoryServices/AdvisoryService";
import ContactUs from "./Components/ContactUs";
import OpportunitiesInner from "./Components/Careers/OpportunitiesInner"
import DeliveryService from "./Service/DeliveryServices/DeliverServices";
import StrategicEvaluation from "./Service/AdvisoryServices/StrategicEvaluation";
import StrategySelectionSystem from "./Service/AdvisoryServices/SSSystems";
import AssessmentSelectionOutsourcingSolutions from "./Service/AdvisoryServices/ASOSlotions";
import BenchmarkingSolutions from "./Service/AdvisoryServices/BenchmarkingSolutions";
import DeliverySub from "./Service/DeliveryServices/DeliverySub";
import MarketsServed from "./Components/MarketsServed/MarketsServed";
import MarketServedList from "./Components/MarketsServed/MarketServedList";
import Expertise from "./Components/Expertises/Expertise";
import About from "./Components/About/About";
// import ManagementTeam from "./Components/About/ManagementTeam";
import SocialResponsibility from "./Components/About/SocialResponsibility";
import Events from "./Components/About/Events";
import OurVendorRelationship from "./Components/About/OurVendorRelationship";
import Careers from "./Components/Careers/Careers"
// // import BenefitsCulture from "./Components/Careers/BenifitsandCulture";
import CareersList from "./Components/Careers/CareersList";
import ExpertiseSubData from "./Components/Expertises/ExpertisesSubData";
import Insights from "./Components/Insights/Insights";
import Blog from "./Components/Insights/Blog"
import Resources from "./Components/Insights/Resources"
import BlogData from "./Components/Insights/BlogsData";
import BenifitsAndCulture from "./Components/Careers/BeniftsAndCulture";



function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Services />} path="/Services" />
        <Route element={<AdvisoryService />} path="/Advisory-Service" />
        <Route element={<DeliveryService />} path="/Delivery-Service" />
        <Route element={<StrategicEvaluation />} path="/Strategic-Evaluation" />
        <Route element={<StrategySelectionSystem />} path="/Strategy-Selection-System" />
        <Route element={<AssessmentSelectionOutsourcingSolutions />} path="/Assessment-Selection-Outsourcing-Solutions" />
        <Route element={<BenchmarkingSolutions />} path="/Benchmarking-Solutions" />
        {/* <Route element={<ProgramProjectManagement/>} path = "/Program-Project-Management" /> */}
        <Route path="/Delivery-Service/:id" element={<DeliverySub />} /> {/* Add the dynamic route */}
        <Route element={<MarketsServed />} path="/Markets-Served" />
        {/* <Route element={<AssetOwners />} path="/Asset-Owners" /> */}
        <Route element={<MarketServedList />} path="/Markets-Served/:id" />
        {/* <Route element={< />} path="/management-team" /> */}
        <Route element={<Events />} path="/events" />
        <Route element={<ContactUs />} path="/Contact-Us" />
        <Route element={<Careers />} path="/Careers" />
        <Route element={<Insights />} path="/Insights" />
        <Route element={<Blog />} path="/blog" />
        <Route element={<Resources />} path="/resources" />
        
        <Route element={<BlogData/>} path = "/blog/:id"/>

        <Route element={<Expertise />} path="/Expertise" />
        <Route element={<ExpertiseSubData />} path="/Experties/:id" />
        <Route element={<About />} path="/About" />
        <Route element={<CareersList />} path="/Careers/:id" />
        <Route element = {<BenifitsAndCulture/>} path="/Careers/BenifitsAndCulture"/>
        {/* <Route element = {<BenefitsCulture/>} path = "/Careers/:id"/> */}
        <Route element={<OpportunitiesInner />} path="/Careers/OpportunitiesInner/:id" />

        <Route
          element={<OurVendorRelationship />}
          path="/our-vendor-relationship"
        />
        <Route
          element={<SocialResponsibility />}
          path="/social-responsibility"
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
