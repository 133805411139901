import React from "react";
import Blogsimage from "../../images/blogssubData.png";
import BlogsSubData from "./BlogsSubData";
import { useParams } from "react-router-dom";

const BlogsData = () => {
  const { id } = useParams();
  console.log("IDD", id);
  const blog = BlogsSubData[id];

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const renderDescription = (description) => {
    return Array.isArray(description) ? (
      description.map((descItem, index) => {
        if (typeof descItem === "object" && descItem !== null) {
          return (
            <div key={descItem.id || index}>
              <h6>{descItem.heading}</h6>
              <p>{descItem.describe}</p>
            </div>
          );
        } else {
          return <p key={index}>{descItem}</p>;
        }
      })
    ) : (
      <p>{description}</p>
    );
  };

  const image = blog.bannerImage;

  return (
    <div>
      <div key={blog.id}>
        <div className="inner-banner">
          <div>
            <img src={image} alt="" />
          </div>
        </div>

        <div
          className="wrapper-1"
          style={{ margin: "20px 11%  0px", width: "78%" }}
        >
          <div className="services-section-text1">
            <h2>
              <span className="black-color">{blog.title}</span>
            </h2>
            <p>
              {Array.isArray(blog.subText)
                ? blog.subText.join(" ")
                : blog.subText}
            </p>
          </div>
        </div>

        <div
          className="bolgs-content"
          style={{ margin: "0px 11%", width: "78%" }}
        >
          {blog.content.map((item) => (
            <div key={item.id}>
              <h5>{item.heading}</h5>
              {renderDescription(item.description)}
            </div>
          ))}
        </div>
      </div>
      <div class="text-center">
        <a href="/blog" class="button-3">
          Back to Blog
        </a>
      </div>
      <br />
    </div>
  );
};

export default BlogsData;
