import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/About.css";
import Managementteam from "../../images/management-team.png";
import Linkedin from "../../images/linkedin.png";

function OurVendorRelationship() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Our Vendor Relationships</h1>
          <p>
            We collaborate with premier service and technology providers to
            revolutionize the asset management industry.
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>Our client partnerships thrive on collaboration</h1>
            <p>
              With over three decades of experience, we've crafted cutting-edge
              technology and operations architectures through close
              collaboration with solutions providers across the investment
              management lifecycle. As a vendor-agnostic consultancy, we boast
              extensive expertise in solutions and services from various firms.
              From laying the foundation of your technology portfolio to shaping
              your product roadmap, understanding your organization is key to
              our shared success. To discover more about Techvest Global and our
              vendor collaboration approach, reach out to our dedicated vendor
              relationship team using the form below.
            </p>
          </div>
        </div>
      </div>
      <div className="team-sec1-bg">
        <div className="wrapper-1">
          <div className="wrap-1">
            <div className="social-content-list">
              <h4>Transition Support Services for Service Providers</h4>
              <h5>Augment your team with outsourcing transition specialists</h5>
              <p>
                Facilitating a smooth transition for your clients' operations is
                no small feat. To facilitate large-scale programs effectively,
                adopting a flexible resource model enables cost containment and
                leverages specialized domain expertise. With over 50 outsourcing
                transitions under our belt, our consulting team possesses
                unparalleled subject matter knowledge across various program
                roles, spanning from project management to testing. Our
                extensive experience within asset management firms ensures a
                deep understanding of your clients' requirements, paving the way
                for cost-effective efficiencies and expedited transitions.
              </p>
            </div>
            <div className="social-content-list">
              <h4>Deployment Solutions</h4>
              <h5>Concentrate on technology while we handle the rest.</h5>
              <p>
                While you've invested significant resources in groundbreaking
                technology, we've mirrored that dedication by cultivating an
                exceptional consulting team. From project management to business
                analysis, data strategy to change management, and comprehensive
                testing strategies, we excel in implementation intricacies.
                Whether you require a partner to extend your professional
                services team or consultants to support a complete
                implementation, we'll seamlessly onboard your clients, ensuring
                a smooth transition.
              </p>
            </div>

            <div className="social-content-list">
              <h4>Collaborative Thought Leadership</h4>
              <h5>
                Engage in industry discourse driving asset management evolution.
              </h5>
              <p>
                As a frontrunner in technology and services for investment
                managers, you possess invaluable insights into the industry's
                evolving trends and challenges. We can broaden your perspective
                and illuminate fresh insights through collaborative thought
                leadership initiatives. From webinars to speaking engagements
                and whitepapers, our industry experts possess the expertise to
                elevate your thought leadership endeavors to new heights.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <div className="our-vendor-relationship-form">
              <h3>
                Want to Learn More? Get in Touch! Reach out to us to discuss
                your objectives or to discover further insights into our
                collaboration with vendors.
              </h3>

              <div className="our-vendor-relationship-form-flex">
                <div className="ff-1">
                  <input type="text" placeholder="First Name" />
                </div>
                <div className="ff-1">
                  <input type="text" placeholder="Last Name" />
                </div>
                <div className="ff-1">
                  <input type="text" placeholder="Email" />
                </div>
                <div className="ff-1">
                  <input type="text" placeholder="Phone Number" />
                </div>
                <div className="ff-1">
                  <input type="text" placeholder="Company Name" />
                </div>
                <div className="ff-1">
                  <input type="text" placeholder="Job Title" />
                </div>
                <div className="ff-1 w-100">
                  <input type="text" placeholder="Region" className="" />
                </div>
                <div className="ff-1 w-100">
                  <textarea placeholder="Message"></textarea>
                </div>
                <div className="ff-1 w-100 text-left">
                  <b>Read Our Privacy Policy Here</b>
                </div>
                <div className="ff-1 w-100 text-left">
                  <button className="button-3">Submit</button>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurVendorRelationship;
