import React from "react";
import { NavLink } from "react-router-dom";
import blogOne from "../../images/Blog-1.png";
import blogTwo from "../../images/Blog-2.png";
import blogThree from "../../images/Blog-3.png";
import blogFour from "../../images/Blog-4.png";
import BlogsSubData from "./BlogsSubData";

function truncateText(text, length) {
  return text.length > length ? text.substring(0, length) + "..." : text;
}

function Resources() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const blogImages = [blogOne, blogTwo, blogThree, blogFour];
  const blogsKeys = Object.keys(BlogsSubData);

  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Blog</h1>
          <p>
            Explore perspectives shared by our consulting team and senior
            thought leaders.
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="two-col-main card-sec text-left">
            {blogsKeys.map((key, index) => {
              const resource = BlogsSubData[key];
              const image = blogImages[index % blogImages.length];
              return (
                <div className="two-col-1" key={resource.id}>
                <img src={resource.image} alt={resource.id} />
                  <div className="blog-title-two-col">
                    <div className="blog-title-two-col-1">
                      <h4>{truncateText(resource.title, 30)}</h4>
                    </div>
                    <div className="blog-title-two-col-2">
                      {/* <h6>Jun 05, 2024</h6> */}
                    </div>
                  </div>
                  <p>{truncateText(resource.subText, 100)}</p>
                  <NavLink
                    to={`/blog/${resource.url}`}
                    className="button-line"
                    onClick={scrollToTop}
                  >
                    Read More
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="wrapper-1">
        <div className="line-2"></div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <a href="#" className="button-3">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
