import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import MarketServedData from "./MarketServedData";


function MarketsServed() {

  const AssetOwnerData = MarketServedData.AssetOwners;
  const Institutional = MarketServedData.Institutional;
  const Insurance = MarketServedData.Insurance;
  const PrivateMarket = MarketServedData.PrivateMarket;
  const RetailData = MarketServedData.Retail;
  const WealthData = MarketServedData.Wealth;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Market Served</h1>
          <p>Expert Financial Consulting for Diverse Markets
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>We know your business.</h1>
            <p>
            At TechVest, we have a deep understanding of the unique challenges and opportunities within your business. Our comprehensive financial consulting services are tailored to meet the specific needs of a wide range of market segments. Whether you are an asset owner, institutional investor, insurer, or involved in private markets, retail, or wealth management, TechVest provides the expertise and insights necessary to drive success in your sector.

            </p>
          </div>
        </div>
      </div>
      <div className="inner-section-3-bg">
        <div className="wrapper-1">
          <div className="m-three-col-main">
            <div className="m-three-col-1">
              <h4>Asset Owners</h4>
              <p>
              TechVest offers specialized consulting services for asset owners, including pension funds, endowments, and foundations. We help you optimize portfolio performance, manage risk, and achieve your long-term financial goals. Our strategies are designed to align with your specific investment objectives and fiduciary responsibilities.

              </p>
              
              <NavLink to= {`/Markets-Served/${AssetOwnerData.id}`} onClick={scrollToTop}>
                Read More
              </NavLink>
            </div>

            <div className="m-three-col-1  margin-top-1">
              <h4>Institutional</h4>
              <p>
              Institutional investors face unique challenges that require tailored solutions. TechVest’s consultants bring extensive experience in managing large-scale portfolios, providing insights into market trends, and optimizing investment strategies. We work closely with institutional clients to enhance portfolio management, improve governance, and achieve superior investment outcomes.
              </p>
              <NavLink to= {`/Markets-Served/${Institutional.id}`}>Read More</NavLink>
            </div>

            <div className="m-three-col-1">
              <h4>Wealth Management</h4>
              <p>
              For wealth managers, providing personalized financial advice and exceptional service is essential. TechVest offers consulting services that enhance client engagement, improve portfolio management, and streamline operations. Our strategies help wealth managers deliver superior results, build trust, and foster long-term client relationships.

              </p>
              <NavLink to= {`/Markets-Served/${WealthData.id}`} >Read More</NavLink>
            </div>

            <div className="m-three-col-1 ">
              <h4>Insurance</h4>
              <p>
              For insurance companies, managing assets and liabilities efficiently is critical. TechVest provides consulting services that address the complexities of regulatory requirements, risk management, and investment strategies. Our solutions are designed to improve financial stability, enhance investment returns, and ensure compliance with industry standards.

              </p>
              <NavLink to= {`/Markets-Served/${Insurance.id}`}>Read More</NavLink>
            </div>

            <div className="m-three-col-1  margin-top-1">
              <h4>Private Markets</h4>
              <p>
              Navigating private markets requires specialized expertise. TechVest supports clients involved in private equity, real estate, and other alternative investments with comprehensive consulting services. We help you identify opportunities, conduct due diligence, and optimize your investment strategies to achieve maximum returns.

              </p>
              <NavLink to= {`/Markets-Served/${PrivateMarket.id}`}>Read More</NavLink>
            </div>

            <div className="m-three-col-1 ">
              <h4>Retail</h4>
              <p>
              In the retail financial services sector, delivering tailored solutions and excellent service is key to client satisfaction. TechVest offers consulting solutions that enhance customer engagement, streamline service delivery, and optimize product offerings. Our expertise helps retail financial service providers deliver superior results and build lasting customer relationships.

              </p>
              <NavLink to= {`/Markets-Served/${RetailData.id}`}>Read More</NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketsServed;
