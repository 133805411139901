import React from "react";
import { NavLink, useParams } from "react-router-dom";
import "../../styles/Services.css";
import SubData from "./DeliveryServiceSubData";
import { Link } from "react-router-dom";

function DeliverySub() {
  const { id } = useParams(); // Get the URL parameter

  // Filter SubData to get the specific data entry based on the URL parameter
  const data = SubData[id];

  if (!data) {
    // Handle case where data for the given id is not found
    return (
      <div>
        <h1>Data not found!</h1>
      </div>
    );
  }

  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>{data.BannerTitle}</h1>
          <p>{data.BannerSubTitle}</p>
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <Link to="/Delivery-Service">Delivery Services</Link>
            <a href="#">{data.BannerTitle}</a>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="services-section-wrap text-left">
            <div className="services-section-1">
              <div className="services-section-text1">
                <h1>
                  <span className="black-color">{data.MainHeading}</span>
                </h1>
                <p>{data.HeadingDescription}</p>
              </div>
            </div>
            <div className="services-section-2">
              {data.BoxData.map((box) => (
                <div className="services-section-2-wrap" key={box.id}>
                  <h3>{box.Heading}</h3>
                  <h6>{box.SubHeading}</h6>
                  <p>{box.Descritpion}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div class="text-center">
            <a href="/Delivery-Service" class="button-3">
              Back to Delivery Services
            </a>
          </div>
          <div className="line-2"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>

            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliverySub;
