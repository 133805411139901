

const CareersSubData = {
  Opportunities: {
    id: "CareerOpportunities",
    url: "Opportunities ",
    title: "Opportunities",
    BannerText:
      "Forge a New Path in Your Career by Applying to Join the Consulting Team at Techvest Global",
    Heading: "Techvest Global is Expanding its Team",
    HeadingDescription:
      "We are seeking consultants with diverse backgrounds in banking and financial services across various functions within the investment lifecycle. We value motivated individuals with high professionalism and a positive attitude. Our hiring process focuses on consultant roles tailored to different levels of experience. Explore our featured roles below to identify where you could make an impact.",
    content: [
      {
        id: "Director",
        haeding: "Director",
        subheading: "",
        descritpion:
          "At Techvest Global, our Directors are dedicated to providing top-tier consulting services and nurturing client partnerships. The key factor that sets apart a Director role is the extensive experience in independent work and the capability to handle various responsibilities ranging from supporting business development to managing engagements and driving thought leadership initiatives. Directors boast proven success records in overseeing large-scale programs throughout the investment management lifecycle and are motivated to assume leadership roles.",
      },
      {
        id: "PrincipalConsultant ",
        haeding: "Principal Consultant ",
        subheading: "",
        descritpion:
          "At Techvest Global, Principal Consultants possess a high level of experience, professionalism, subject matter expertise, and gravitas, allowing them to smoothly shift from business development to crucial delivery and knowledge management functions. This role demands extensive consulting and project management skills, coupled with the ability to operate autonomously while enhancing client relationships.",
      },
      {
        id: "SeniorConsultant",
        haeding: "Senior Consultant",
        subheading: "",
        descritpion:
          "At Techvest Global, our Senior Consultants possess profound expertise in buy-side operations and thrive in dynamic client environments with minimal oversight. They excel in delivering project management, process enhancements, and domain expertise across diverse client projects. Their proven track record of consistent success in the industry reflects their dedication to achieving client excellence.",
      },
    ],
  },
  
};

export default CareersSubData;
