import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
import CareersSubData from "./CareersSubData";

function Careers() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  const Oppurtunites = CareersSubData.Opportunities;
  // const BenefitsandCulture = CareersSubData.BenefitsandCulture;

  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Careers 
          </h1>
          <p>
          Explore the latest trends, technologies, and practices that are revolutionizing the industry.
          <br/>
          Dive into Financial Services Transformation
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>Elevate Your Career with an Exceptional Consulting Team.</h1>
            <p>
            At Techvest Global, our consultants come from diverse backgrounds in Banking and Financial Services, bonded by their commitment to excellence and camaraderie. We prioritize the well-being of our team by offering comprehensive tools, benefits, and growth opportunities. Fostering a culture of employee engagement is essential to our success
            </p>
          </div>
        </div>
      </div>
      <div className="inner-section-2-bg">
        <div className="wrapper-1">
          <div className="services-card2-section">
            <div className="services-card2-1">
              <h3>Career Opportunities</h3>
              <p>
              At Techvest Global, we prioritize diversity, employee well-being, and professional growth in the Banking and Financial Services sector. Our inclusive workplace fosters collaboration and innovation, supported by comprehensive benefits and continuous learning opportunities. We offer diverse career paths in consulting, technology, risk management, data analytics, and project management. Our employees thrive in a culture of camaraderie and excellence, contributing to groundbreaking projects that shape the future of finance. Join us to be part of a dynamic team dedicated to transforming the financial landscape and achieving success together. Explore our career opportunities and start your journey with Techvest Global.
              </p>

              <NavLink
                to = {`/Careers/${Oppurtunites.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Benefits and Culture</h3>
              <p>
              At Techvest Global, our team members are paramount. We provide an exceptional work environment equipped with resources for professional development, comprehensive benefits to foster well-being, and a collaborative culture that celebrates and acknowledges achievements.

              </p>
              <NavLink
                   to = {`/Careers/BenifitsAndCulture`}
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
