const AboutData = {
    About: {
        id: "About",
        url: "About",
        BannerText: "About",
        Heading: "Experience is Invaluable",
        HeadingDescription: "Welcome to TechVest Global, where innovation meets expertise in the ever-evolving landscape of banking and financial services. We have established ourselves as trusted advisors and solution providers to major financial institutions and service providers worldwide.",
        content: [
            {
                id: "Our Expertise",
                haeding: "Our Expertise",
                subheading: "",
                descritpion: "At TechVest Global, we specialize in navigating the complexities of the financial industry. From collaborating with leading service providers, and product firms to partnering with diverse financial clients, our journey has been defined by our commitment to delivering tailored solutions that drive business growth and operational efficiency.",
            },
            {
                id: "Comprehensive Solutions",
                haeding: "Comprehensive Solutions",
                subheading: "",
                descritpion: "Our extensive experience spans a spectrum of services, from integrating third-party products to developing bespoke software solutions. We understand that every client has unique challenges and objectives. Therefore, we leverage our deep industry knowledge and technical proficiency to deliver scalable, innovative solutions that address specific business needs.",
            },
            {
                id: "Client-Centric Approach",
                haeding: "Client-Centric Approach",
                subheading: "",
                descritpion: "At the heart of our success lies our client-centric approach. We prioritize understanding our clients' goals, challenges, and operational nuances. By forging strong partnerships and maintaining open communication, we ensure that our solutions not only meet but exceed expectations.",
            },
            {
                id: "Driving Business Value",
                haeding: "Driving Business Value",
                subheading: "",
                descritpion: "Our proven track record in custom software development, fintech solutions, and regulatory compliance enables us to empower our clients with the tools and insights they need to stay ahead in a competitive market. Whether enhancing operational efficiency, optimizing customer experience, or navigating regulatory landscapes, TechVest Global is dedicated to delivering tangible business value.",
            },
            {
                id: "Commitment to Excellence",
                haeding: "Commitment to Excellence",
                subheading: "",
                descritpion: "At TechVest Global, excellence is ingrained in everything we do. We continuously invest in our people, processes, and technologies to remain at the forefront of industry trends and innovations. Our team of seasoned professionals combines industry expertise with a passion for innovation, ensuring that we deliver solutions that are not only effective but also future-proof.",
            },
            {
                id: "Partner with Us",
                haeding: "Partner with Us",
                subheading: "",
                descritpion: "Partner with TechVest Global to leverage our decades of industry experience and technical prowess. Together, we can navigate the complexities of the financial services industry, transform challenges into opportunities, and achieve sustainable growth and success.Discover how TechVest Global can empower your business to thrive in the digital age. Contact us today to explore customized solutions tailored to your unique business needs.",
            },
        ]
    }
}


export default AboutData