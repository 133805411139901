const SubData = {
  ProgramandProjectManagement: {
    id: "ProgramandProjectManagement",
    url: "Program-and-Project-Management",
    BannerTitle: "Program & Project Management",
    BannerSubTitle:
      "Master Your Projects with TechVest’s Expert Program and Project Management Services",
    MainHeading: "Crafting a winning strategy is key to program success.",
    HeadingDescription:
      "We understand that successful program and project management is essential for achieving strategic goals and maintaining operational excellence. Our services combine deep, modern techniques with rich experience to handle complex projects efficiently and effectively. We ensure that your projects are delivered on time, within budget, and to the highest standards of quality. Partner with us to navigate the project management process with confidence and achieve unparalleled results.",
    BoxData: [
      {
        id: "Advanced Techniques and Methodologies",
        Heading: "Advanced Techniques and Methodologies",
        //   SubHeading: "Managing a program demands meticulous planning, relentless focus, and unwavering attention to detail.",
        Descritpion:
          "TechVest employs advanced project management techniques and methodologies to ensure the success of your projects. We utilize agile, waterfall, and hybrid approaches tailored to your specific needs, ensuring flexibility and precision in execution. Our cutting-edge tools and technologies enable us to plan, monitor, and control every aspect of the project lifecycle, from initiation to closure. By leveraging these modern techniques, we help you mitigate risks, optimize resources, and achieve your project objectives.",
      },
      {
        id: "Rich Experience in Handling Complex Projects",
        Heading: "Rich Experience in Handling Complex Projects",
        //   SubHeading: "A successful program hinges on a solid strategy.",
        Descritpion:
          "Our battle-tested project frameworks have powered some of the industry's most groundbreaking technology and operations initiatives. From the get-go, we'll collaborate with your project or program team to gain insights into your environment and craft essential documents like project definitions, current state assessments, and timelines. As we dive into execution, we'll establish a robust program management office and implement the necessary tools, policies, and plans to keep everything on track. Throughout the journey, we'll proactively manage risks, handle reporting, and ensure seamless communication with stakeholders. Regardless of whether your program follows a waterfall, agile, or hybrid approach, our hands-on experience spans across all project methodologies.",
      },
      {
        id: "Resource and Budget Optimization",
        Heading: "Resource and Budget Optimization",
        //    SubHeading: "Our consultants boast a wealth of experience in overseeing asset management programs of all magnitudes.",
        Descritpion:
          " Effective resource and budget management is crucial for project success. TechVest’s project managers are skilled in optimizing resources and budgets to maximize value and minimize waste. We conduct thorough planning and forecasting to allocate resources efficiently and ensure that your budget is utilized effectively. Our proactive approach to financial management ensures that your projects stay on track and within budget, delivering the expected ROI.",
      },
      {
        id: "Navigate the Process with Confidence",
        Heading: "Navigate the Process with Confidence",
        //   SubHeading: "Stay ahead of potential obstacles by anticipating challenges before they hinder progress.",
        Descritpion:
          "Navigating the complexities of program and project management can be daunting, but with TechVest by your side, you can proceed with confidence. Our comprehensive support and transparent communication keep you informed and involved throughout the project lifecycle. We provide regular updates, address issues promptly, and make data-driven decisions to steer your projects toward successful completion. With TechVest, you gain a reliable partner committed to your success.",
      },
      // {
      //     id: "Maintain focus on your objectives",
      //     Heading: "Maintain focus on your objectives",
      //     SubHeading: "Ensure timely and cost-effective resource allocation across your organization.",
      //     Descritpion: "Efficiently managing resources while staying on schedule and within budget is a daunting task for programs of all sizes. Our team possesses the expertise and manpower to keep your project goals in clear sight through robust enterprise resource management. By gaining insight into current and future resource allocations, you'll effectively mitigate program risks while preserving business continuity."
      // },
    ],
  },

  ImplementingandIntegratingSystems: {
    id: "ImplementingandIntegratingSystems",
    url: "Implementing-and-Integrating-Systems",
    BannerTitle: "Systems Implementation & Integration",
    BannerSubTitle:
      "Seamlessly Integrate and Implement with our Expert Services",
    MainHeading:
      "Integrate innovative technology seamlessly into your operations without disruption.",
    HeadingDescription:
      "We specialize in delivering seamless implementation and integration solutions that enhance your organization’s efficiency and performance. Our services are underpinned by deep, modern techniques and rich experience in the latest cutting-edge technologies. We handle complex integrations with precision and expertise, ensuring that your systems work harmoniously and optimally. Partner with TechVest to leverage our consultants' extensive experience and achieve unparalleled success in your implementation and integration projects.",
    BoxData: [
      {
        id: "Expertise in Cutting-Edge Technologies",
        Heading: "Expertise in Cutting-Edge Technologies",
        //  SubHeading: "Simplify complex implementations for efficient outcome",
        Descritpion:
          "Our team has extensive experience with the latest cutting-edge technologies, ensuring that your implementation and integration projects are at the forefront of innovation. From cloud computing and artificial intelligence to blockchain and IoT, TechVest consultants are well-versed in the most advanced tech solutions. We stay ahead of industry trends, providing you with state-of-the-art systems that enhance your operational capabilities and drive your business forward.",
      },
      {
        id: "Harness real expertise",
        Heading: "Harness real expertise",
        //  SubHeading: "Partner with professionals who understand your industry inside out.",
        Descritpion:
          "For your program to succeed, you require a team with deep insights into your sector. Our consultants boast an average of 18 years in the industry, providing unparalleled understanding of your peers, vendors, and successful projects across various scales. This wealth of experience enables us to hit the ground running, asking the pertinent questions and proposing innovative solutions without delay. Utilize our expertise to drive project efficiency, foresee obstacles, and unearth opportunities throughout your program journey.",
      },
      {
        id: "Handling Complex Integrations",
        Heading: "Handling Complex Integrations",
        //  SubHeading: "Navigate resource limitations while sustaining daily operations.",
        Descritpion:
          "TechVest excels in managing complex integrations, ensuring that disparate systems work together seamlessly. Our consultants have successfully integrated a wide range of applications, platforms, and technologies, overcoming challenges related to compatibility, data migration, and system interoperability. We meticulously plan and execute integration projects, ensuring that your systems communicate effectively and function as a cohesive unit. Our expertise minimizes downtime and maximizes the efficiency of your integrated environment.",
      },
      {
        id: "Experienced Consultants",
        Heading: "Experienced Consultants",
        //  SubHeading: "Harness a roadmap for implementation triumph.",
        Descritpion:
          "Our consultants bring a wealth of experience to every implementation and integration project. With a proven track record across various industries, TechVest’s team has the skills and knowledge to tackle even the most challenging projects. Our consultants’ deep understanding of technology and business processes ensures that we deliver solutions that meet your unique needs and exceed your expectations. We are committed to providing exceptional service and achieving the best possible outcomes for your organization.",
      },
      // {
      //     id: "Prepare for sustained success",
      //     Heading: "Prepare for sustained success",
      //     SubHeading: "Secure comprehensive training and transition support for your team.",
      //     Descritpion: "A successful implementation isn’t just about reaching the go-live milestone. It extends beyond to encompass knowledge transfer and post-production support, which are essential facets of your implementation initiative. We are committed to facilitating a seamless transition to your new technology by offering continuous training and stabilization services."
      // },
    ],
  },
  OutsourcingTransition: {
    id: "OutsourcingTransition",
    url: "Outsourcing-Transition",
    BannerTitle: "Outsourcing Transition",
    BannerSubTitle:
      "Prepare and streamline your operations with a seasoned outsourcing transition partner.",
    MainHeading: "Smoothly integrate your operations",
    HeadingDescription:
      "Bringing a third party into your operational ecosystem demands trust and resources. Without adequate support, the transition process can consume valuable time and effort, resulting in fragmented data streams or redundant functions. We specialize in bridging the gap between you and your service provider, ensuring efficient and effective outcomes. With experience in transitions of all sizes and complexities, our seasoned consultants excel in both outsourcing and providing outsourcing services.",
    BoxData: [
      {
        id: "Ensure a seamless transition without disrupting your operations.",
        Heading:
          "Ensure a seamless transition without disrupting your operations.",
        SubHeading:
          "Transitioning to an outsourced operating model while maintaining business as usual demands a significant effort.",
        Descritpion:
          "From data standardization to project communications to testing, managing every aspect of the transition requires focused attention. Our consultants act as an extension of your team, handling all aspects of the transition process, allowing your business to operate smoothly with minimal disruption.",
      },
      {
        id: "Harness expert insights",
        Heading: "Harness expert insights",
        SubHeading:
          "Accelerate your transition and minimize risk with our asset management outsourcing specialists.",
        Descritpion:
          "Your outsourcing plan should optimize operations and reduce costs, yet many transitions falter in execution. Without a seasoned guide, unexpected hurdles can derail progress. With a track record of over 50 successful transitions across all major service providers, no consultancy is better equipped to navigate the process. Our hands-on experience, combined with profound domain knowledge, empowers you to navigate the transition swiftly and with confidence, minimizing surprises along the way.",
      },
      {
        id: "Stay on course",
        Heading: "Stay on course",
        SubHeading:
          "Navigate key milestones with adept project management and delivery proficiency.",
        Descritpion:
          "Are you well-versed in the intricacies of an outsourcing transition? Without a seasoned partner to steer the process, determining its scope and sequence can be daunting. Our consultants will establish transparent expectations at each phase and augment your team to supervise the delivery of standardized data and vital files, while managing stakeholder communications, conducting testing, and ensuring post-transition stabilization. Throughout the transition journey, our project management specialists will monitor critical milestones to ensure timely and cost-effective realization of your new operating model.",
      },
      {
        id: "Maintain focus on the ultimate objective",
        Heading: "Maintain focus on the ultimate objective",
        SubHeading:
          "Empower your investment strategy with a sustainable long-term blueprint.",
        Descritpion:
          "Your outsourcing model should empower your investment strategy by aligning with appropriate technology and services, all while realizing cost and scale efficiencies. Yet, too often, the journey toward this envisioned future is hindered when resources become consumed in addressing immediate challenges during the transition phase. With our seasoned expertise, we anticipate potential obstacles and maintain a steadfast focus on the overarching vision. Through strategic design of your operational model and meticulous management of the entire transition process, we ensure you stay on course to achieve optimal operational efficiency.",
      },
    ],
  },
};

export default SubData;
