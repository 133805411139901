
import BenifitsAndCultureimage from "../../images/Rectangle 133.png"

const BenifitsAndCulture = {
  BenefitsandCulture: {
    id: "BenefitsandCulture",
    url: "BenefitsandCulture",
    title: "Benefits and Culture",
    BannerText:
      "We prioritize the growth and well-being of our team members through investments in our workplace, comprehensive benefits packages, and robust professional development programs.",
    Heading:
      "Explore the distinctive culture that attracts the best talent in the industry",
    HeadingDescription:
      "Exceptional client service stems from our internal culture. We prioritize cultivating an exceptional workplace environment, offering top-tier benefits, competitive compensation, and fostering a collaborative atmosphere. Dive deeper below to uncover what sets Techvest Global apart as an exceptional workplace.",
    
    SubContent : [
      {
        id: "Culture",
        haeding: "Culture",
       image : BenifitsAndCultureimage,
        subheading:
          "Our culture thrives on a work hard, play hard ethos, fostering an environment where employees excel.",
        descritpion:
          "we prioritize collaboration, supporting each other's accomplishments. Transparency is paramount, with open communication from leadership to every team member. Enjoy a business casual atmosphere that promotes comfort and productivity. We're dedicated to facilitating a flexible work-life balance, ensuring our team's well-being. Community engagement is encouraged, with opportunities for charitable initiatives. Participate in annual team-building events and ongoing employee engagement programs. Experience the vibrant culture that sets Techvest Global apart as an exceptional workplace.",
      },
    ],
      content: [
      // {
      //   id: "Culture",
      //   haeding: "Culture",
      //   // images : {benifitsAndculture},
      //   subheading:
      //     "Our culture thrives on a work hard, play hard ethos, fostering an environment where employees excel.",
      //   descritpion:
      //     "we prioritize collaboration, supporting each other's accomplishments. Transparency is paramount, with open communication from leadership to every team member. Enjoy a business casual atmosphere that promotes comfort and productivity. We're dedicated to facilitating a flexible work-life balance, ensuring our team's well-being. Community engagement is encouraged, with opportunities for charitable initiatives. Participate in annual team-building events and ongoing employee engagement programs. Experience the vibrant culture that sets Techvest Global apart as an exceptional workplace.",
      // },
      {
        id: "Employee Recognition",
        haeding: "Employee Recognition",
        subheading:
          "Techvest Global consultants consistently exceed expectations",
        descritpion:
          "At Techvest Global, our culture is inspired by the principles of Rowanism, as outlined in the timeless business essay, A Message to Garcia. This concept, named after Andrew Summers Rowan, epitomizes pragmatism, focus, and strategic acumen. Like true Rowans, our consultants rise to any challenge, surpassing all expectations with their commitment and dedication. We make it a priority to acknowledge and reward employees who consistently go above and beyond the call of duty.",
      },
      {
        id: "Professional Development",
        haeding: "Professional Development",
        subheading:
          "At Techvest Global, we believe in the symbiotic relationship between our employees' success and our own",
        descritpion:
          "That's why we prioritize investing in our talent's professional development as a means to foster our collective growth. Alongside continuous mentorship, each employee benefits from a dedicated resource manager and personalized performance reviews. We also provide comprehensive company-wide training, opportunities for continuing education, memberships to industry associations, and a steadfast commitment to promoting from within.",
      },
      {
        id: "Benefits",
        haeding: "Benefits",
        subheading:
          "we understand the importance of providing comprehensive benefits that enable our consultants to thrive both professionally and personally.",
        descritpion:
          "Our benefits package is designed to attract and retain top talent in the industry, offering competitive compensation, including salary, bonuses, and additional earning potential. We prioritize work-life balance with a generous paid time off policy and ensure our employees' well-being with robust health insurance coverage and retirement plans.",
      },
    ],
  },
}

export default BenifitsAndCulture