import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo.png";
import MarketServedData from "../Components/MarketsServed/MarketServedData";
import ExpertisesData from "../Components/Expertises/ExpertiesData";
import CareersSubData from "../Components/Careers/CareersSubData";
// import BenifitsandCulture from "../Components/Careers/BandCData";
// import BenifitsandCultureData from "../Components/Careers/BandCData";

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleListItemClick = () => {
    toggleVisibility();
  };

  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const AssetOwnerData = MarketServedData.AssetOwners;
  const Institutional = MarketServedData.Institutional;
  const Insurance = MarketServedData.Insurance;
  const PrivateMarket = MarketServedData.PrivateMarket;
  const RetailData = MarketServedData.Retail;
  const WealthData = MarketServedData.Wealth;

  //Experties Navigation pathss::
  const FrontOffice = ExpertisesData.FrontOffice;
  const BackOffice = ExpertisesData.BackOffice;
  const MiddleOffice = ExpertisesData.MiddleOffice;
  const DataManagment = ExpertisesData.DataManagement;
  const AlternativeInvestment = ExpertisesData.Alternative;
  const outsourcing = ExpertisesData.Outsourcing;

  //Careers Naviagtion Paths::

  const Oppurtunites = CareersSubData.Opportunities;
  // const BenefitsandCulture = CareersSubData.BenefitsandCulture;

  // const BenifitsandCulture = BenifitsandCultureData.BenifitsandCulture;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="mobile-menu ">
        <div className="wrapper">
          <div className="mobile-wrap ">
            <div className="mobile-1">
              <NavLink to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className={scrolled ? "logo scrolled" : "logo"}
                />
              </NavLink>
            </div>
            <div className="mobile-2">
              <button onClick={toggleVisibility} className="mobile-menu-btn">
                {isVisible ? "Hide" : "Menu"}
              </button>
            </div>
            <div className="mobile-3">
              {isVisible && (
                <div className="mobile-3-height">
                  <div className="mobile-navigation">
                    {/* Mobile menu Start */}
                    <ul>
                      <li onClick={scrollToTop}>
                        <NavLink to="/" onClick={handleListItemClick}>
                          Home
                        </NavLink>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink to="/Services" onClick={handleListItemClick}>
                          Services
                        </NavLink>
                        <ul>
                          <li>
                            <NavLink
                              to="/Advisory-Service"
                              onClick={handleListItemClick}
                            >
                              Consulting Service
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to="/Delivery-Service"
                              onClick={handleListItemClick}
                            >
                              Delivery Service
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink
                          to="/Markets-Served"
                          onClick={handleListItemClick}
                        >
                          Markets Served
                        </NavLink>
                        <ul>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Markets-Served/${AssetOwnerData.id}`}
                              onClick={handleListItemClick}
                            >
                              Asset Owners
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Markets-Served/${Institutional.id}`}
                              onClick={handleListItemClick}
                            >
                              Institutional
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Markets-Served/${Insurance.id}`}
                              onClick={handleListItemClick}
                            >
                              Insurance
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Markets-Served/${PrivateMarket.id}`}
                              onClick={handleListItemClick}
                            >
                              Private Markets
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Markets-Served/${RetailData.id}`}
                              onClick={handleListItemClick}
                            >
                              Retail
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Markets-Served/${WealthData.id}`}
                              onClick={handleListItemClick}
                            >
                              Wealth
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink to="/Expertise" onClick={handleListItemClick}>
                          Expertise
                        </NavLink>
                        <ul>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Experties/${FrontOffice.id}`}
                              onClick={handleListItemClick}
                            >
                              Front Office
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Experties/${MiddleOffice.id}`}
                              onClick={handleListItemClick}
                            >
                              Middle Office
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Experties/${BackOffice.id}`}
                              onClick={handleListItemClick}
                            >
                              Back Office
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Experties/${DataManagment.id}`}
                              onClick={handleListItemClick}
                            >
                              Data Management
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Experties/${AlternativeInvestment.id}`}
                              onClick={handleListItemClick}
                            >
                              Alternative Operations
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Experties/${outsourcing.id}`}
                              onClick={handleListItemClick}
                            >
                              Outsourcing
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink to="/About" onClick={handleListItemClick}>
                          About
                        </NavLink>
                        <ul>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to="/social-responsibility"
                              onClick={handleListItemClick}
                            >
                              Social Responsibility
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink to="/events" onClick={handleListItemClick}>
                              Events
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink to="/Insights" onClick={handleListItemClick}>
                          Insights
                        </NavLink>
                        <ul>
                          <li onClick={scrollToTop}>
                            <NavLink to="/blog" onClick={handleListItemClick}>
                              Blog
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to="/resources"
                              onClick={handleListItemClick}
                            >
                              Resources
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink to="/Careers" onClick={handleListItemClick}>
                          Careers
                        </NavLink>
                        <ul>
                          <li onClick={scrollToTop}>
                            <NavLink
                              to={`/Careers/${Oppurtunites.id}`}
                              onClick={handleListItemClick}
                            >
                              Opportunities
                            </NavLink>
                          </li>
                          <li onClick={scrollToTop}>
                            {/* <NavLink to= {`/Careers/${BenifitsandCulture.id}`}> */}
                            <NavLink
                              to={`/Careers/BenifitsAndCulture`}
                              onClick={handleListItemClick}
                            >
                              Benefits and Culture
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink to="/Contact-Us" onClick={handleListItemClick}>
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                    {/* Mobile menu End */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <nav className={scrolled ? "navbar scrolled" : "navbar"}>
        <button className="menu-toggle" onClick={handleMenuToggle}>
          ☰
        </button>
        <ul className={`navbar-menu ${menuOpen ? "open" : ""}`}>
          <li onClick={scrollToTop}>
            <NavLink to="/">Home</NavLink>
          </li>

          <li onClick={scrollToTop} className="dropdown relative">
            <NavLink
              to="/Services"
              className="dropdown-link"
              onClick={handleMenuToggle}
            >
              Services
            </NavLink>
            <ul className="dropdown-menu">
              <li onClick={scrollToTop}>
                <NavLink to="/Advisory-Service">Consulting Service</NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to="/Delivery-Service">Delivery Service</NavLink>
              </li>
            </ul>
          </li>

          <li onClick={scrollToTop} className="dropdown relative">
            <NavLink to="/Markets-Served" className="dropdown-link">
              Markets Served
            </NavLink>
            <ul className="dropdown-menu">
              <li onClick={scrollToTop}>
                <NavLink to={`/Markets-Served/${AssetOwnerData.id}`}>
                  Asset Owners
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Markets-Served/${Institutional.id}`}>
                  Institutional
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Markets-Served/${Insurance.id}`}>
                  Insurance
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Markets-Served/${PrivateMarket.id}`}>
                  Private Markets
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Markets-Served/${RetailData.id}`}>
                  Retail
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Markets-Served/${WealthData.id}`}>
                  Wealth
                </NavLink>
              </li>
            </ul>
          </li>

          <li onClick={scrollToTop} className="dropdown relative">
            <NavLink to="/Expertise" className="dropdown-link">
              Expertise
            </NavLink>
            <ul className="dropdown-menu">
              <li onClick={scrollToTop}>
                <NavLink to={`/Experties/${FrontOffice.id}`}>
                  Front Office
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Experties/${MiddleOffice.id}`}>
                  Middle Office
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Experties/${BackOffice.id}`}>
                  Back Office
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Experties/${DataManagment.id}`}>
                  Data Management
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Experties/${AlternativeInvestment.id}`}>
                  Alternative Operations
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to={`/Experties/${outsourcing.id}`}>
                  Outsourcing
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="logo-main" onClick={scrollToTop}>
            <NavLink to="/">
              {/* <img src={Logo} alt="Logo" /> */}
              <img
                src={Logo}
                alt="Logo"
                className={scrolled ? "logo scrolled" : "logo"}
              />
            </NavLink>
          </li>

          <li onClick={scrollToTop} className="dropdown relative">
            <NavLink to="/About" className="dropdown-link">
              About
            </NavLink>
            <ul className="dropdown-menu">
              {/* <li onClick={scrollToTop}>
                <NavLink to="/management-team">Management Team</NavLink>
              </li> */}
              <li onClick={scrollToTop}>
                <NavLink to="/social-responsibility">
                  Social Responsibility
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to="/events">Events</NavLink>
              </li>
              {/* <li onClick={scrollToTop}>
                <NavLink to="/our-vendor-relationship">
                  Our Vendor Relationship
                </NavLink>
              </li> */}
            </ul>
          </li>

          <li onClick={scrollToTop} className="dropdown relative">
            <NavLink to="/Insights" className="dropdown-link">
              Insights
            </NavLink>
            <ul className="dropdown-menu">
              <li onClick={scrollToTop}>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li onClick={scrollToTop}>
                <NavLink to="/resources">Resources</NavLink>
              </li>
            </ul>
          </li>

          <li className="dropdown relative">
            <NavLink to="/Careers" className="dropdown-link">
              Careers
            </NavLink>
            <ul className="dropdown-menu">
              <li onClick={scrollToTop}>
                <NavLink to={`/Careers/${Oppurtunites.id}`}>
                  Opportunities
                </NavLink>
              </li>
              <li onClick={scrollToTop}>
                {/* <NavLink to= {`/Careers/${BenifitsandCulture.id}`}> */}
                <NavLink to={`/Careers/BenifitsAndCulture`}>
                  Benefits and Culture
                </NavLink>
              </li>
            </ul>
          </li>

          <li onClick={scrollToTop}>
            <NavLink to="/Contact-Us">Contact Us</NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
