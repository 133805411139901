import React from "react";
import { NavLink } from "react-router-dom";
import StrategicEvaluation from "../../images/events-img-1.png";
import BenchmarkingSolutions from "../../images/events-img-2.png";
import AssessmentSelection from "../../images/events-img-3.png";
import StrategySelectionSystems from "../../images/events-img-4.png";

function Events() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Events</h1>
          <p>
            Participate in industry discussions by attending our conferences,
            events, and webinars.
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>content will be posted soon..</h1>
            {/* <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution
            </p> */}
          </div>
          <br />
          <br />
          {/* <div className="two-col-main card-sec text-left">
            <div className="two-col-1">
              <img src={StrategicEvaluation} alt="StrategicEvaluation" />
              <h3>Lorem Ipsum</h3>
              <p>
                t is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout.
              </p>
              <NavLink to="/" className="button-line" onClick={scrollToTop}>
                Read Event Details
              </NavLink>
            </div>

            <div className="two-col-1">
              <img
                src={StrategySelectionSystems}
                alt="StrategySelectionSystems"
              />
              <h3>Lorem Ipsum</h3>
              <p>
                t is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout.
              </p>
              <NavLink to="/" className="button-line" onClick={scrollToTop}>
                Read Event Details
              </NavLink>
            </div>
            <div className="two-col-1">
              <img src={AssessmentSelection} alt="AssessmentSelection" />
              <h3>Lorem Ipsum</h3>
              <p>
                t is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout.
              </p>
              <NavLink to="/" className="button-line" onClick={scrollToTop}>
                Read Event Details
              </NavLink>
            </div>

            <div className="two-col-1">
              <img src={BenchmarkingSolutions} alt="BenchmarkingSolutions" />
              <h3>Lorem Ipsum</h3>
              <p>
                t is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout.
              </p>
              <NavLink to="/" className="button-line" onClick={scrollToTop}>
                Read Event Details
              </NavLink>
            </div>
          </div> */}
        </div>
      </div>
      <div className="wrapper-1">
        <div className="line-3"></div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <a href="#" className="button-3">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
