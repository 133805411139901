import React from "react";
import { NavLink, useParams } from "react-router-dom";
import benifitsandculture from "./benifitsandculturedata"
import "../../styles/About.css";

function BenifitsAndCulture() {
  const data = benifitsandculture.BenefitsandCulture;

  return (
    <div>
      {/* Inner banner section */}
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>{data.title}</h1>
          <p style={{ verticalAlign : "middle"}}>{data.BannerText}</p>
        </div>
      </div>

      {/* Section with main content */}
      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>{data.Heading}</h1>
            <p>{data.HeadingDescription}</p>
          </div>
        </div>
      </div>

     {/*  */}
     <div  style={{background: "#f8f8f8", minHeight: "100vh" }}>
        <div className="wrapper-1">
        {data.SubContent.map((item)=> (
            <div className="" key = {item.id}>
                 <div className="opp-sub-card wrapper-1" style={{ display: "flex" }}>
                    <img src = {item.image} alt = {item.haeding} style={{margin:"40px 30px 30px 0px" }}/>
                    <div style={{margin:"40px 30px 39px 0px"}}>
                  <h3>{item.haeding}</h3>
                  <h5>{item.subheading}</h5>
                  <p style={{lineHeight:"normal"}}>{item.descritpion}</p>
                </div>
                 </div>
            </div>
        ))}
        </div>
        <hr className="wrapper-1 hr-line" style={{background: "#A4A4A4;" , width:"76%" ,}}/>
      {/* Render content based on the selected section */}
      <div className="team-sec1-bg">
        <div className="wrapper-1">
          {data.content.map((item) => (
            <div  key={item.id}>
              <div className="opp-sub-card wrapper-1" style={{ display: "flex" , marginTop:"25px" ,marginBottom:"20px"}}>
                {item.image && (
                  <img
                    src={item.image}
                    alt={item.haeding}
                    style={{ width: "150px", marginRight: "20px" }}
                  />
                )}
                <div>
                  <h3>{item.haeding}</h3>
                  <h5>{item.subheading}</h5>
                  <p>{item.descritpion}</p>
                </div>
              </div>
              <hr className="wrapper-1 hr-line" style={{background: "#A4A4A4;" , width:"90%" ,}}/>
              {/* <div className="opportunities-card-link">
                <a href="#" className="button-3">
                  Apply Now
                </a>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      </div>

      {/* Additional section */}
      {/* <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="line-3"></div>
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default BenifitsAndCulture;
