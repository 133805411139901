import React from "react";
import { NavLink } from "react-router-dom";
import StrategicEvaluation from "../../images/Program-and-Project-Management.jpg";
import StrategySelectionSystems from "../../images/Systems-Implementation-and-Integration.jpg";
import AssessmentSelection from "../../images/outsourcing-transition.jpg";
import SubData from "./DeliveryServiceSubData";

function DeliveryService() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const programData = SubData.ProgramandProjectManagement;
  const systemsData = SubData.ImplementingandIntegratingSystems;
  const OutsourcingData = SubData.OutsourcingTransition;

  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Delivery Services</h1>
          <p>
            Achieve Seamless Execution with TechVest’s Expert Delivery Services
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>Embrace a practical mindset to realize your strategy.</h1>
            <p>
              TechVest's Delivery Services are designed to ensure seamless
              execution and optimal performance across all facets of your
              organization. From comprehensive program and project management to
              systems implementation and integration, and smooth outsourcing
              transitions, our expert team is dedicated to driving your success.
              With a focus on efficiency, reliability, and strategic alignment,
              we provide tailored solutions that meet your unique needs and
              propel your business forward.
            </p>
          </div>
          <div className="two-col-main card-sec text-left">
            <div className="two-col-1">
              <img src={StrategicEvaluation} alt="StrategicEvaluation" />
              <h3>Program & Project Management</h3>
              <p>
                Effective program and project management is crucial for
                achieving strategic objectives and maintaining operational
                efficiency. TechVest’s seasoned professionals bring extensive
                experience in managing complex projects from inception to
                completion. We utilize advanced methodologies and tools to plan,
                execute, and monitor projects, ensuring they are delivered on
                time, within budget, and to the highest quality standards. Our
                proactive approach to risk management and stakeholder
                communication ensures that your projects align with your
                business goals and deliver measurable results.
              </p>
              <NavLink
                to={`/Delivery-Service/${programData.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="two-col-1">
              <img
                src={StrategySelectionSystems}
                alt="StrategySelectionSystems"
              />
              <h3>Systems Implementation & Integration</h3>
              <p>
                Seamless systems implementation and integration are vital for
                maintaining operational continuity and enhancing business
                performance. TechVest specializes in the deployment of
                cutting-edge technologies and the integration of diverse systems
                to create cohesive, efficient, and scalable IT environments. Our
                expert team works closely with you to understand your unique
                requirements and design solutions that streamline processes,
                enhance data flow, and improve overall system functionality.
                From initial planning and configuration to testing and
                deployment, we ensure a smooth transition and minimal disruption
                to your operations.
              </p>
              <NavLink
                to={`/Delivery-Service/${systemsData.id}`}
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="two-col-1">
              <img src={AssessmentSelection} alt="AssessmentSelection" />
              <h3>Outsourcing Transition</h3>
              <p>
                Whether you're an asset manager shifting to a new service
                provider or an asset servicer in search of transition support,
                our outsourcing transition experts will enhance your team and
                offer expertise throughout the transition lifecycle.
              </p>
              {/* Placeholder for correct URL */}
              <a
                href={`/Delivery-Service/${OutsourcingData.id}`}
                className="button-2"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-1">
        <div className="line-2"></div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <a href="/Contact-Us" className="button-3">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryService;
