import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Services.css";
//import BlogOne from "../images/Blog-1.png";

function Insights() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner">
        <div className="inner-banner-text">
          <h1>Insights</h1>
          <p>
            We simplify complexity to provide valuable solutions on emerging
            technology, operations, and strategy within the Banking and
            Financial Services sector.
          </p>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h1>Deep Dive for Actionable Insights</h1>
            <p>
              Our consultants actively contribute to shaping and implementing
              the insights we share through our blog, whitepapers, webinars, and
              more, rather than just discussing industry trends. Discover the
              latest industry discussions, understand how they impact your
              business, and explore strategies to stay ahead in the face of
              evolving technology and operational challenges.
            </p>
          </div>
        </div>
      </div>
      <div className="inner-section-2-bg">
        <div className="wrapper-1">
          <div className="services-card2-section">
            <div className="services-card2-1">
              <h3>Resources</h3>
              <p>
                We engage actively in industry discussions across multiple
                platforms. Whether you prefer webinars, client stories, or
                whitepapers, our resources provide in-depth insights into the
                trends shaping our industry's evolution.
              </p>

              <NavLink
                to="/resources"
                className="button-2"
                onClick={scrollToTop}
              >
                Read More
              </NavLink>
            </div>

            <div className="services-card2-1">
              <h3>Blog</h3>
              <p>
                Our Blogs gather perspectives from our client engagements.
                Explore observations and industry trends shared by our
                contributors, or deep dive into transformative insights led by
                our thought leaders.
              </p>
              <NavLink to="/blog" className="button-2" onClick={scrollToTop}>
                Read More
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section-1-wrap">
        <div className="wrapper-1">
          <div className="wrap-1">
            <h2>
              Know what you need?
              <br />
              Submit your
              <span className="black-color"> Request</span> for information
              <span className="blue-color"> Here.</span>
            </h2>
            <NavLink to="/Contact-Us" className="button-3">
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insights;
